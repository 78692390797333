import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-please-wait',
  templateUrl: './please-wait.template.html',
  styleUrls: ['./please-wait.style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf],
})
export class PleaseWaitComponent {
  @Input() public message = '';

  constructor() {}
}
