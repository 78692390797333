import { Injectable } from '@angular/core';
import { ApiError } from '@app/shared/models/api/api-error.model';
import { Query } from '@datorama/akita';
import { Observable, distinctUntilChanged, map } from 'rxjs';
import { AkitaStartingPricesState } from '../models/starting-prices.state';
import { AkitaStartingPricesStore } from './starting-prices.store';
import { StartingPriceModel } from '@app/shared/models/api/starting-price.model';
import { AkitaProductsQuery } from '../../products/state/products.query';

@Injectable({ providedIn: 'root' })
export class AkitaStartingPricesQuery extends Query<AkitaStartingPricesState> {
  constructor(
    protected readonly store: AkitaStartingPricesStore,
    protected readonly akitaProductsQuery: AkitaProductsQuery
  ) {
    super(store);
  }

  public getStartingPrices(): Array<StartingPriceModel> {
    const country = this.akitaProductsQuery.validateLocale().country;
    return this.getValue().startingPrices[country || ''] || [];
  }

  public selectStartingPricesError(): Observable<ApiError | null> {
    return this.select().pipe(
      map((state: AkitaStartingPricesState) => state.errorFetchingStartingPrices || null),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectFetchingStartingPrices(): Observable<boolean> {
    return this.select().pipe(
      map((state: AkitaStartingPricesState) => Boolean(state.fetchingStartingPrices)),
      distinctUntilChanged()
    );
  }

  public selectStartingPrices(): Observable<Array<StartingPriceModel>> {
    const country = this.akitaProductsQuery.validateLocale().country;
    return this.select().pipe(
      map((state: AkitaStartingPricesState) =>
        state.startingPrices[country || ''] ? state.startingPrices[country || ''] : []
      ),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectIPhones(): Observable<Array<StartingPriceModel>> {
    const country = this.akitaProductsQuery.validateLocale().country;

    return this.select().pipe(
      map((state: AkitaStartingPricesState) =>
        state.startingPrices[country || '']
          ? state.startingPrices[country || ''].filter(
              (x) => x.brand === 'Apple' && x.category === 'SMARTPHONE' && x.inStock
            )
          : []
      ),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectMacBooks(): Observable<Array<StartingPriceModel>> {
    const country = this.akitaProductsQuery.validateLocale().country;

    return this.select().pipe(
      map((state: AkitaStartingPricesState) =>
        state.startingPrices[country || '']
          ? state.startingPrices[country || ''].filter(
              (x) => x.brand === 'Apple' && x.category === 'LAPTOP' && x.inStock
            )
          : []
      ),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectIPads(): Observable<Array<StartingPriceModel>> {
    const country = this.akitaProductsQuery.validateLocale().country;

    return this.select().pipe(
      map((state: AkitaStartingPricesState) =>
        state.startingPrices[country || '']
          ? state.startingPrices[country || ''].filter(
              (x) => x.brand === 'Apple' && x.category === 'TABLET' && x.inStock
            )
          : []
      ),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectAirPods(): Observable<Array<StartingPriceModel>> {
    const country = this.akitaProductsQuery.validateLocale().country;

    return this.select().pipe(
      map((state: AkitaStartingPricesState) =>
        state.startingPrices[country || '']
          ? state.startingPrices[country || ''].filter(
              (x) => x.brand === 'Apple' && x.category === 'HEADPHONES' && x.inStock
            )
          : []
      ),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectTablets(): Observable<Array<StartingPriceModel>> {
    const country = this.akitaProductsQuery.validateLocale().country;

    return this.select().pipe(
      map((state: AkitaStartingPricesState) =>
        state.startingPrices[country || '']
          ? state.startingPrices[country || ''].filter(
              (x) => x.category === 'TABLET' && x.brand !== 'Apple' && x.inStock
            )
          : []
      ),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectWatches(): Observable<Array<StartingPriceModel>> {
    const country = this.akitaProductsQuery.validateLocale().country;

    return this.select().pipe(
      map((state: AkitaStartingPricesState) =>
        state.startingPrices[country || '']
          ? state.startingPrices[country || ''].filter(
              (x) => x.category === 'WATCH' && x.inStock
            )
          : []
      ),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectAccessories(): Observable<Array<StartingPriceModel>> {
    const country = this.akitaProductsQuery.validateLocale().country;

    return this.select().pipe(
      map((state: AkitaStartingPricesState) =>
        state.startingPrices[country || '']
          ? state.startingPrices[country || ''].filter(
              (x) => x.category === 'ACCESSORIES' && x.inStock
            )
          : []
      ),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectEarbuds(): Observable<Array<StartingPriceModel>> {
    const country = this.akitaProductsQuery.validateLocale().country;

    return this.select().pipe(
      map((state: AkitaStartingPricesState) =>
        state.startingPrices[country || '']
          ? state.startingPrices[country || ''].filter(
              (x) => x.category === 'EARBUDS' && x.inStock
            )
          : []
      ),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectHeadphones(): Observable<Array<StartingPriceModel>> {
    const country = this.akitaProductsQuery.validateLocale().country;

    return this.select().pipe(
      map((state: AkitaStartingPricesState) =>
        state.startingPrices[country || '']
          ? state.startingPrices[country || ''].filter(
              (x) => x.category === 'HEADPHONES' && x.brand !== 'Apple' && x.inStock
            )
          : []
      ),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectGaming(): Observable<Array<StartingPriceModel>> {
    const country = this.akitaProductsQuery.validateLocale().country;

    return this.select().pipe(
      map((state: AkitaStartingPricesState) =>
        state.startingPrices[country || '']
          ? state.startingPrices[country || ''].filter(
              (x) => x.category === 'GAMING' && x.inStock
            )
          : []
      ),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectSamsung(): Observable<Array<StartingPriceModel>> {
    const country = this.akitaProductsQuery.validateLocale().country;

    return this.select().pipe(
      map((state: AkitaStartingPricesState) =>
        state.startingPrices[country || '']
          ? state.startingPrices[country || ''].filter(
              (x) => x.brand === 'Samsung' && x.category === 'SMARTPHONE' && x.inStock
            )
          : []
      ),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectSmartphones(): Observable<Array<StartingPriceModel>> {
    const country = this.akitaProductsQuery.validateLocale().country;

    return this.select().pipe(
      map((state: AkitaStartingPricesState) =>
        state.startingPrices[country || '']
          ? state.startingPrices[country || ''].filter(
              (x) => x.category === 'SMARTPHONE' && x.inStock
            )
          : []
      ),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectLaptops(brand: string): Observable<Array<StartingPriceModel>> {
    const country = this.akitaProductsQuery.validateLocale().country;

    return this.select().pipe(
      map((state: AkitaStartingPricesState) =>
        state.startingPrices[country || '']
          ? state.startingPrices[country || ''].filter(
              (x) => x.brand === brand && x.category === 'LAPTOP' && x.inStock
            )
          : []
      ),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectBrands(): Observable<Array<string>> {
    const country = this.akitaProductsQuery.validateLocale().country;

    return this.select().pipe(
      map((state: AkitaStartingPricesState) => {
        const uniquesBrands: Array<string> = new Array(0);
        if (state.startingPrices[country || '']) {
          state.startingPrices[country || ''].forEach((x) => {
            if (
              !uniquesBrands.includes(x.brand) &&
              x.brand !== 'Samsung' &&
              x.brand !== 'Apple'
            ) {
              uniquesBrands.push(x.brand);
            }
          });

          uniquesBrands.unshift('Apple'); // apple first
        }
        return uniquesBrands;
      }),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectLaptopsBrands(): Observable<Array<string>> {
    const country = this.akitaProductsQuery.validateLocale().country;

    return this.select().pipe(
      map((state: AkitaStartingPricesState) => {
        const uniquesBrands: Array<string> = new Array(0);
        if (state.startingPrices[country || '']) {
          state.startingPrices[country || ''].forEach((x) => {
            if (
              !uniquesBrands.includes(x.brand) &&
              x.category === 'LAPTOP' &&
              x.inStock &&
              x.brand !== 'Apple'
            ) {
              uniquesBrands.push(x.brand);
            }
          });

          uniquesBrands.unshift('Apple'); // apple first
        }
        return uniquesBrands;
      }),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }
}
