import { Injectable, NgZone } from '@angular/core';
import { parseApiError } from '@app/shared/models/api/api-error.model';
import { applyTransaction, logAction } from '@datorama/akita';
import { TranslocoService } from '@ngneat/transloco';
import {
  Observable,
  of,
  Subscription,
  throwError,
  catchError,
  map,
  tap,
  distinctUntilChanged,
} from 'rxjs';
import { AvailableProductLocaleResponse } from '../models/product-available-country-lang.response.model';
import { ProductSearchFilterOptions } from '../models/product-search.filter-options.model';
import {
  generateFiltersID,
  ProductSearchFilters,
} from '../models/product-search.filters.model';
import { ProductSearchResults } from '../models/product-search.results.model';
import { ProductVariant } from '../models/product-variant.model';
import { Product } from '../models/product.model';
import { AkitaProductsState } from '../models/products.state';
import { ProductsAPIService } from '../services/products.api.service';
import { AkitaProductsQuery } from './products.query';
import { AkitaProductsStore } from './products.store';
import { Insurance } from '../models/insurance.model';

@Injectable({ providedIn: 'root' })
export class AkitaProductsService {
  constructor(
    private readonly zone: NgZone,
    private readonly store: AkitaProductsStore,
    private readonly query: AkitaProductsQuery,
    private readonly translateService: TranslocoService,
    private readonly productsApiService: ProductsAPIService
  ) {}

  public searchAsync(options?: ProductSearchFilters | null): Subscription {
    return this.search(options).subscribe({
      next: () => {},
      error: () => {},
    });
  }

  public search(options?: ProductSearchFilters | null): Observable<ProductSearchResults> {
    const path = generateFiltersID(options);
    const page = options?.page || 0;

    // CHECK FOR EXISTING RESULTS on the state
    if (
      this.query.hasSearchResultsPage(path, page) ||
      this.query.getIsSearchLoading(path)
    ) {
      return of(this.query.getSearchPage(path, page) || new ProductSearchResults());
    }

    this.zone.run(() => {
      applyTransaction(() => {
        logAction('search()');
        this.store.setIsSearchingResults(path, options?.page, true);
        this.store.setErrorSearchingResults(path, options?.page, null);
      });
    });

    return this.productsApiService.search(options).pipe(
      catchError((error: unknown) => {
        const parsed = parseApiError(error);
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('search() - error');
            this.store.setIsSearchingResults(path, options?.page, false);
            this.store.setErrorSearchingResults(path, options?.page, parsed);
          });
        });
        return throwError(() => parsed);
      }),
      tap((results: ProductSearchResults) => {
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('search() - done');
            this.store.setIsSearchingResults(path, options?.page, false);
            this.store.setSearchResults(path, options?.page, results);
          });
        });
      })
    );
  }

  public getAvailableSearchOptionsAsync(
    options?: ProductSearchFilters | null
  ): Subscription {
    return this.getAvailableSearchOptions(options).subscribe({
      next: () => {},
      error: () => {},
    });
  }

  public getAvailableSearchOptions(
    options?: ProductSearchFilters | null
  ): Observable<ProductSearchFilterOptions> {
    const path = generateFiltersID(options);

    // CHECK FOR EXISTING OPTIONS on the state
    if (
      this.query.hasProductFilters(path) ||
      this.query.getIsFetchingProductFilters(path)
    ) {
      return of(this.query.getProductFilters(path));
    }

    this.zone.run(() => {
      applyTransaction(() => {
        logAction('getAvailableSearchOptions()');
        this.store.setIsFetchingAvailableProductFilters(path, true);
        this.store.setErrorFetchingAvailableProductFilters(path, null);
      });
    });

    return this.productsApiService.getAvailableFiltersForSearch(options).pipe(
      catchError((error: unknown) => {
        const parsed = parseApiError(error);
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('getAvailableSearchOptions() - error');
            this.store.setIsFetchingAvailableProductFilters(path, false);
            this.store.setErrorFetchingAvailableProductFilters(path, parsed);
          });
        });
        return throwError(() => parsed);
      }),
      tap((availableOptions: ProductSearchFilterOptions) => {
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('getAvailableSearchOptions() - done');
            this.store.setIsFetchingAvailableProductFilters(path, false);
            this.store.setAvailableProductFilters(path, availableOptions);
          });
        });
      })
    );
  }

  public getAvailableProductOptionsAsync(model: string): Subscription {
    return this.getAvailableProductOptions(model).subscribe({
      next: () => {},
      error: () => {},
    });
  }

  public getAvailableProductOptions(
    model: string
  ): Observable<ProductSearchFilterOptions | null> {
    const filters: ProductSearchFilters = {
      ...new ProductSearchFilters(),
      country: this.query.country,
      language: this.query.language,
    };
    if (model.length) {
      filters.model = [...new Array(0), model];
    }

    const path = generateFiltersID(filters);

    // CHECK FOR EXISTING OPTIONS on the state
    if (
      this.query.hasProductFilters(path) ||
      this.query.getIsFetchingProductFilters(path)
    ) {
      return this.query.select().pipe(
        map((state: AkitaProductsState) => state.availableProductFilters[path]),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
      );
    }

    this.zone.run(() => {
      applyTransaction(() => {
        logAction('getAvailableSearchOptions()');
        this.store.setIsFetchingAvailableProductFilters(path, true);
        this.store.setErrorFetchingAvailableProductFilters(path, null);
      });
    });

    return this.productsApiService.getProductOptions(model).pipe(
      catchError((error: unknown) => {
        const parsed = parseApiError(error);

        return throwError(() => parsed);
      }),
      tap((availableOptions: ProductSearchFilterOptions | null) => {
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('getAvailableSearchOptions() - done');
            this.store.setIsFetchingAvailableProductFilters(path, false);
            this.store.setAvailableProductFilters(path, availableOptions);
          });
        });
      })
    );
  }

  public getProductAsync(id?: string | null): Subscription {
    return this.getProduct(id).subscribe({
      next: () => {},
      error: () => {},
    });
  }

  public getProduct(id?: string | null): Observable<Product | null> {
    const validLocale = this.query.validateLocale();
    const lang = validLocale.language;
    const country = validLocale.country;

    // CHECK FOR EXISTING ON THE STATE
    if (this.query.getIsFetchingProduct(id)) {
      return of(null);
    }
    if (this.query.getProduct(id)) {
      return of(this.query.getProduct(id));
    }

    this.zone.run(() => {
      applyTransaction(() => {
        logAction('getProduct()');
        this.store.setIsProductFetching(lang, country, id, true);
        this.store.setErrorFetchingProduct(lang, country, id, null);
      });
    });

    return this.productsApiService.getProduct(id, country).pipe(
      catchError((error: unknown) => {
        const parsed = parseApiError(error);
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('getProduct() - error');
            this.store.setIsProductFetching(lang, country, id, false);
            this.store.setErrorFetchingProduct(lang, country, id, parsed);
          });
        });
        return throwError(() => parsed);
      }),
      tap((product: Product | null) => {
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('getProduct() - done');
            this.store.setIsProductFetching(lang, country, id, false);
            this.store.setProduct(lang, country, id, product);
          });
        });
      })
    );
  }

  public getProductVariantsByModelAsync(
    model?: string | null,
    category?: string | null
  ): Subscription {
    return this.getProductVariantsByModel(model, category).subscribe({
      next: () => {},
      error: () => {},
    });
  }

  public getProductVariantsByModel(
    model?: string | null,
    category?: string | null
  ): Observable<Array<ProductVariant>> {
    const locale = this.query.validateLocale(
      `${this.translateService.getActiveLang() || ''}`.toLowerCase(),
      this.query.country || ''
    );
    const country = locale?.country || 'US';
    const lang = locale?.language || 'en';

    if (model && lang && country) {
      // CHECK FOR EXITING VARIANTS ON THE STATE
      if (
        (this.query.getValue().productVariants[lang] &&
          this.query.getValue().productVariants[lang][country] &&
          this.query.getValue().productVariants[lang][country][model]) ||
        this.query.getIsFetchingProductVariants(model)
      ) {
        return of(new Array(0));
      }

      this.zone.run(() => {
        applyTransaction(() => {
          logAction('getModelVariants()');
          this.store.setIsSearchingProductVariants(lang, country, model, true);
          this.store.setErrorSearchingProductVariants(lang, country, model);
        });
      });

      return this.productsApiService.getVariantsFromModel(model, category).pipe(
        catchError((error: unknown) => {
          const parsed = parseApiError(error);
          this.zone.run(() => {
            applyTransaction(() => {
              logAction('getModelVariants() - error');
              this.store.setIsSearchingProductVariants(lang, country, model, false);
              this.store.setErrorSearchingProductVariants(lang, country, model, parsed);
            });
          });
          return throwError(() => parsed);
        }),
        tap((productVariants: Array<ProductVariant>) => {
          this.zone.run(() => {
            applyTransaction(() => {
              logAction('getModelVariants() - done');
              this.store.setIsSearchingProductVariants(lang, country, model, false);
              this.store.setProductVariants(lang, country, model, productVariants);
            });
          });
        })
      );
    } else {
      return of(new Array(0));
    }
  }

  public getAvailableProductLocaleInfoAsync(): Subscription {
    return this.getAvailableProductLocaleInfo().subscribe({
      next: () => {},
      error: () => {},
    });
  }

  public getAvailableProductLocaleInfo(): Observable<AvailableProductLocaleResponse> {
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('getAvailableProductLocaleInfo()');
        this.store.setGettingAvailableProductLocaleInfo(true);
        this.store.setErrorGettingAvailableProductLocaleInfo(null);
      });
    });

    return this.productsApiService.getAvailableCountryAndLanguages().pipe(
      catchError((error: unknown) => {
        const parsed = parseApiError(error);
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('getAvailableProductLocaleInfo() - error');
            this.store.setGettingAvailableProductLocaleInfo(false);
            this.store.setErrorGettingAvailableProductLocaleInfo(parsed);
          });
        });
        return throwError(() => parsed);
      }),
      tap((response: AvailableProductLocaleResponse) => {
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('getAvailableProductLocaleInfo() - done');
            this.store.setGettingAvailableProductLocaleInfo(false);
            this.store.setAvailableProductLocaleInfo(response);
          });
        });
      })
    );
  }

  public fetchProductSearchHintsAsync(): Subscription {
    return this.fetchProductSearchHints().subscribe({
      next: () => {},
      error: () => {},
    });
  }

  public fetchProductSearchHints(): Observable<Array<string>> {
    const lang = this.query.validateLocale().language;
    const country = this.query.validateLocale().country;

    if (this.query.getGettingProductSearchHints()) {
      return of(new Array(0));
    }

    this.zone.run(() => {
      applyTransaction(() => {
        logAction('fetchProductSearchHints()');
        this.store.setGettingProductSearchHints(true);
        this.store.setErrorGettingProductSearchHints(null);
      });
    });

    return this.productsApiService.getProductTitles(lang, country).pipe(
      catchError((error: unknown) => {
        const parsed = parseApiError(error);
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('fetchProductSearchHints() - error');
            this.store.setGettingProductSearchHints(false);
            this.store.setErrorGettingProductSearchHints(parsed);
          });
        });
        return throwError(() => parsed);
      }),
      tap((hints: Array<string>) => {
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('fetchProductSearchHints() - done');
            this.store.setGettingProductSearchHints(false);
            this.store.setProductSearchHints(hints);
          });
        });
      })
    );
  }

  public fetchProductByKeyword(keywords: string): Observable<Array<string>> {
    const filters: ProductSearchFilters = {
      ...new ProductSearchFilters(),
      country: this.query.country,
      language: this.query.language,
    };
    filters.keywords = keywords;

    return this.search(filters).pipe(
      map((results) => {
        console.log('results', results);
        return results.results.map((result) => result.title);
      })
    );
  }

  // deprecated
  public fetchProductInsurances(
    product: Product | null,
    language: string
  ): Observable<Array<Insurance> | null> {
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('fetchInsurances()');
      });
    });

    const currency = product?.price?.currency || 'SAR';
    const ids = product?.insuranceIds || [];

    if (ids.length === 0 || this.store.getValue().fetchingInsurances) {
      return of(null);
    }

    applyTransaction(() => {
      this.store.setFetchingInsurances(true);
    });

    return this.productsApiService.getInsurances(currency, language, ids).pipe(
      catchError((error: unknown) => {
        const parsed = parseApiError(error);
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('fetchInsurances() - error');
            this.store.setFetchingInsurances(false);

            /*  this.store.setFetchingInsurances(false);
            this.store.setErrorFetchingInsurances(parsed); */
          });
        });
        return throwError(() => parsed);
      }),
      tap((response: Array<Insurance>) => {
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('fetchInsurances() - done');

            this.store.setProductInsurances(
              product?.id || '',
              language,
              product?.country || 'SA',
              response
            );
            this.store.setFetchingInsurances(false);
          });
        });
        this.zone.run(() => {
          applyTransaction(() => {
            this.store.setFetchingInsurances(false);
          });
        });
      })
    );
  }

  public fetchProductInsuranceAsync(product: Product): Subscription {
    return this.fetchProductInsurance(product).subscribe({
      next: () => {},
      error: () => {},
    });
  }

  public fetchProductInsurance(
    product: Product | null
  ): Observable<Array<Insurance> | null> {
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('fetchInsurances() - ' + product?.insuranceIds?.join(','));
      });
    });

    const language = this.query.validateLocale().language;
    const country = product?.country || 'SA';
    const currency = product?.price?.currency || 'SAR';
    const ids = product?.insuranceIds || [];

    if (
      ids.length === 0 ||
      this.query.getIsFetchingInsurance(language, country, ids.join('-')) ||
      ids.every((id) => this.query.getInsurance(id, country, language))
    ) {
      return of(null);
    }

    applyTransaction(() => {
      this.store.setFetchingInsurance(
        language,
        country,
        product?.insuranceIds?.join('-') || '-',
        true
      );
    });

    return this.productsApiService.getInsurances(currency, language, ids).pipe(
      catchError((error: unknown) => {
        const parsed = parseApiError(error);
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('fetchInsurances() - error');
            applyTransaction(() => {
              this.store.setFetchingInsurance(
                language,
                country,
                product?.insuranceIds?.join('-') || '-',
                false
              );
            });
          });
        });
        return throwError(() => parsed);
      }),
      tap((response: Array<Insurance>) => {
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('fetchInsurances() - done');

            this.store.setProductInsurances(
              product?.id || '',
              language,
              product?.country || 'SA',
              response
            );
            applyTransaction(() => {
              this.store.setFetchingInsurance(
                language,
                country,
                product?.insuranceIds?.join('-') || '-',
                false
              );
            });
          });
        });
        // this.zone.run(() => {
      })
    );
  }

  public addSelectedInsuranceToProduct(id: string, insurance: Insurance | null): void {
    const validLocale = this.query.validateLocale();
    const lang = validLocale.language;
    const country = validLocale.country;

    this.zone.run(() => {
      applyTransaction(() => {
        logAction('addSelectedInsuranceToProduct()');
        this.store.addSelectedInsuranceToProduct(id, insurance, lang, country);
      });
    });
  }
}
