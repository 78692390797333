/* eslint-disable @typescript-eslint/no-magic-numbers */
import { ChangeDetectionStrategy, Component, Input, Signal } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { CommonModule } from '@angular/common';
import { toSignal } from '@angular/core/rxjs-interop';
import { TabbyArIconComponent } from '../../../../../shared/components/icons/generated/tabby-ar/tabby-ar.component';
import { TabbyIconComponent } from '../../../../../shared/components/icons/generated/tabby/tabby.component';

@Component({
  selector: 'popsy-tabby-badge',
  templateUrl: './tabby-badge.template.html',
  styleUrls: ['./tabby-badge.style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, TabbyArIconComponent, TabbyIconComponent],
})
export class TabbyBadgeComponent {
  @Input() public size?: 'small' | 'medium' | 'default' = 'default';

  public readonly language: Signal<string>;

  constructor(private readonly translateService: TranslocoService) {
    this.language = toSignal(this.translateService.langChanges$, {
      initialValue: this.translateService.getActiveLang(),
    });
  }
}
