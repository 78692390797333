<!-- eslint-disable @angular-eslint/template/cyclomatic-complexity -->
<ng-container *transloco="let t">
  <section
    class="navigation-bar-component"
    [class.pill-mode]="pillMode"
    [class.mobile-mode]="mobileMode"
    [class.centered]="centerMode && (biggerThan$ | async)?.sm"
    [class.rtl]="isRtlLayout$ | async">
    <!-- NAVIGATION BAR -->
    @if (!mobileMode) {
      <nav
        class="nav"
        [class.scrollable]="!mobileMode && !(biggerThan$ | async)?.sm"
        [class.mobile-mode]="mobileMode"
        [class.list-mode]="gridMode">
        <div class="burguer-wrap" (click)="toogleAllProducts()">
          @if (showAllProducts === true) {
            <popsy-icon-remove
              class="burguer-icon icon"
              [attr.aria-label]="t('aria_action_close')" />
          } @else {
            <popsy-icon-burguer-menu
              class="burguer-icon icon"
              [attr.aria-label]="t('aria_action_close')" />
          }
        </div>
        @if (!gridMode) {
          <span class="fixed-space-24" aria-hidden="true"></span>
        }
        @if (!mobileMode) {
          @if (enableBuybackFeature() === true) {
            <a
              class="link"
              [routerLink]="getBuybackFragment()"
              [queryParams]="getSearchParams()"
              (click)="logOpenBuyback('navigation-bar')"
              [class.selected]="showDropDown === 'allProducts'">
              <span class="text-secondary">{{ t('label_trade_in') }}</span>
            </a>
            <span aria-hidden="true" class="fixed-space-16"></span>
            @if (!pillMode) {
              <span aria-hidden="true" class="fixed-space-16"></span>
            }
          }

          <a
            class="link"
            [routerLink]="getBestDealsFragment()"
            [queryParams]="getSearchParams()"
            [class.selected]="showDropDown === 'allProducts'">
            <span class="text-secondary">{{ t('title_best_deals') }}</span>
          </a>

          <span aria-hidden="true" class="fixed-space-16"></span>
          @if (!pillMode) {
            <span aria-hidden="true" class="fixed-space-16"></span>
          }

          <a
            class="link"
            [routerLink]="getCategoryCollectionFragment('SMARTPHONE')"
            [queryParams]="getSearchParams(null, 'Apple')">
            <span>iPhones</span>
          </a>
          <span aria-hidden="true" class="fixed-space-16"></span>
          @if (mobileMode) {
            <span class="line"></span>
          }
          @if (!pillMode) {
            <span aria-hidden="true" class="fixed-space-16"></span>
          }
          <a
            class="link"
            [routerLink]="getCategoryCollectionFragment('SMARTPHONE')"
            [queryParams]="getSearchParams(null, 'Samsung')">
            <span>Samsung Phones</span>
          </a>
          <span aria-hidden="true" class="fixed-space-16"></span>
          @if (mobileMode) {
            <span class="line"></span>
          }
          @if (!pillMode) {
            <span aria-hidden="true" class="fixed-space-16"></span>
          }
          <a
            class="link"
            [routerLink]="getCategoryCollectionFragment('LAPTOP')"
            [queryParams]="getSearchParams(null, 'Apple')">
            <span>MacBooks</span>
            <span aria-hidden="true" class="fixed-space-16"></span>
            @if (mobileMode) {
              <span class="line"></span>
            }
            @if (!pillMode) {
              <span aria-hidden="true" class="fixed-space-16"></span>
            }
          </a>
          <a
            class="link"
            [routerLink]="getCategoryCollectionFragment('LAPTOP')"
            [queryParams]="getSearchParams()">
            <span>{{ t('category_laptop_title') }}</span>
          </a>
          <span aria-hidden="true" class="fixed-space-16"></span>
          @if (mobileMode) {
            <span class="line"></span>
          }
          @if (!pillMode) {
            <span aria-hidden="true" class="fixed-space-16"></span>
          }
          <a
            class="link"
            [routerLink]="getCategoryCollectionFragment('TABLET')"
            [queryParams]="getSearchParams(null, 'Apple')">
            <span>iPads</span>
            <span aria-hidden="true" class="fixed-space-16"></span>
            @if (mobileMode) {
              <span class="line"></span>
            }
            @if (!pillMode) {
              <span aria-hidden="true" class="fixed-space-16"></span>
            }
          </a>
          <a
            class="link"
            [routerLink]="getCategoryCollectionFragment('TABLET')"
            [queryParams]="getSearchParams()">
            <span>{{ t('category_tablet_title') }}</span>
          </a>
          <span aria-hidden="true" class="fixed-space-16"></span>
          @if (mobileMode) {
            <span class="line"></span>
          }
          @if (!pillMode) {
            <span aria-hidden="true" class="fixed-space-16"></span>
          }
          <a
            class="link"
            [routerLink]="getCategoryCollectionFragment('WATCH')"
            [queryParams]="getSearchParams()">
            <span>{{ t('category_watch_title') }}</span>
            <span aria-hidden="true" class="fixed-space-16"></span>
            @if (mobileMode) {
              <span class="line"></span>
            }
            @if (!pillMode) {
              <span aria-hidden="true" class="fixed-space-16"></span>
            }
          </a>
          <a
            class="link"
            [routerLink]="getCategoryCollectionFragment('HEADPHONES')"
            [queryParams]="getSearchParams(null, 'Apple')">
            <span>AirPods</span>
            <span aria-hidden="true" class="fixed-space-16"></span>
            @if (mobileMode) {
              <span class="line"></span>
            }
            @if (!pillMode) {
              <span aria-hidden="true" class="fixed-space-16"></span>
            }
          </a>
          <a
            class="link"
            [routerLink]="getCategoryCollectionFragment('HEADPHONES')"
            [queryParams]="getSearchParams()">
            <span>{{ t('category_headphones_title') }}</span>
          </a>
          <span aria-hidden="true" class="fixed-space-16"></span>
          @if (mobileMode) {
            <span class="line"></span>
          }
          @if (!pillMode) {
            <span aria-hidden="true" class="fixed-space-16"></span>
          }
          <a
            class="link"
            [routerLink]="getCategoryCollectionFragment('GAMING')"
            [queryParams]="getSearchParams()">
            <span>{{ t('category_gaming_title') }}</span>
          </a>
          <span aria-hidden="true" class="fixed-space-16"></span>
          @if (mobileMode) {
            <span class="line"></span>
          }
          @if (!pillMode) {
            <span aria-hidden="true" class="fixed-space-16"></span>
          }
          <a
            class="link"
            [routerLink]="getCategoryCollectionFragment('ACCESSORIES')"
            [queryParams]="getSearchParams()">
            <span>{{ t('category_accessories_title') }}</span>
          </a>
          <span aria-hidden="true" class="fixed-space-16"></span>
          @if (mobileMode) {
            <span class="line"></span>
          }
          @if (!pillMode) {
            <span aria-hidden="true" class="fixed-space-16"></span>
          }
        }

        @if (!mobileMode && !gridMode) {
          <a
            class="link"
            [routerLink]="getProductSearchFragment()"
            [queryParams]="getSearchParams()"
            [class.selected]="showDropDown === 'allProducts'">
            <span class="text-secondary">{{ t('label_shop_all') }}</span>
          </a>
        }
      </nav>
    }

    <!-- MAIN DROPDOWN -->
    @if (
      (showAllProducts || mobileMode) &&
      (fetchingStartingPrices$ | async) === false &&
      (startingPricesError$ | async) === null
    ) {
      <div class="drop-down-wrapper">
        <div [class.desktop]="(biggerThan$ | async)?.mobileL" class="drop-down main">
          <!-- allProducts-drop-down -->
          <div class="list iphones-drop-down">
            <span aria-hidden="true" class="fixed-space-16"></span>
            @if (enableBuybackFeature() === true) {
              <a
                class="link"
                [routerLink]="getBuybackFragment()"
                [queryParams]="getSearchParams()"
                (click)="onItemSelected(); logOpenBuyback('burguer-menu')"
                [class.selected]="showDropDown === 'allProducts'">
                <h1 class="text-secondary">{{ t('label_trade_in') }}</h1>
              </a>
              <span aria-hidden="true" class="fixed-space-16"></span>
            }

            <a
              class="link"
              [routerLink]="getBestDealsFragment()"
              [queryParams]="getSearchParams()"
              [class.selected]="showDropDown === 'allProducts'">
              <h1 class="text-secondary">{{ t('title_best_deals') }}</h1>
            </a>

            <span aria-hidden="true" class="fixed-space-16"></span>

            <a
              class="link"
              [routerLink]="getCategoryCollectionFragment('SMARTPHONE')"
              [queryParams]="getSearchParams(null, 'Apple')"
              [class.selected]="showDropDown === 'iPhones'">
              <img class="thumb" alt="iPhones" [src]="sharedImages()?.phone" />
              <h1>iPhones</h1>
              <span class="dynamic-space"></span>
              <popsy-icon-arrow-right-1
                (click)="openDropDown('iPhones', $event)"
                class="arrow"
                [class.rtl]="isRtlLayout$ | async" />
            </a>
            <span aria-hidden="true" class="fixed-space-16"></span>

            <a
              class="link"
              [routerLink]="getCategoryCollectionFragment('SMARTPHONE')"
              [queryParams]="getSearchParams(null, 'Samsung')"
              [class.selected]="showDropDown === 'Samsung Phones'">
              <img class="thumb" alt="Samsung" [src]="sharedImages()?.samsung" />
              <h1>Samsung Phones</h1>
              <span class="dynamic-space"></span>
              <popsy-icon-arrow-right-1
                (click)="openDropDown('Samsung Phones', $event)"
                class="arrow"
                [class.rtl]="isRtlLayout$ | async" />
            </a>
            <span aria-hidden="true" class="fixed-space-16"></span>

            <a
              class="link"
              [routerLink]="getCategoryCollectionFragment('SMARTPHONE')"
              [queryParams]="getSearchParams(null, null)"
              [class.selected]="showDropDown === 'Smartphones'">
              <img class="thumb" alt="Smartphones" [src]="sharedImages()?.smartphone" />
              <h1>Smartphones</h1>
              <span class="dynamic-space"></span>
              <popsy-icon-arrow-right-1
                (click)="openDropDown('Smartphones', $event)"
                class="arrow"
                [class.rtl]="isRtlLayout$ | async" />
            </a>
            <span aria-hidden="true" class="fixed-space-16"></span>

            @if ((macBooks$ | async)?.length) {
              <a
                class="link"
                [routerLink]="getCategoryCollectionFragment('LAPTOP')"
                [queryParams]="getSearchParams(null, 'Apple')"
                [class.selected]="showDropDown === 'macBooks'">
                <img class="thumb" alt="MacBooks" [src]="sharedImages()?.macbook" />
                <h1>MacBooks</h1>
                <span class="dynamic-space"></span>
                <popsy-icon-arrow-right-1
                  (click)="openDropDown('macBooks', $event)"
                  class="arrow"
                  [class.rtl]="isRtlLayout$ | async" />
              </a>
              <span aria-hidden="true" class="fixed-space-16"></span>
            }
            <a
              class="link"
              [routerLink]="getCategoryCollectionFragment('LAPTOP')"
              [queryParams]="getSearchParams(null, null)"
              [class.selected]="showDropDown === 'Laptops'">
              <img class="thumb" alt="Laptops" [src]="sharedImages()?.laptop" />
              <h1>{{ t('category_laptop_title') }}</h1>
              <span class="dynamic-space"></span>
              <popsy-icon-arrow-right-1
                (click)="openDropDown('Laptops', $event)"
                class="arrow"
                [class.rtl]="isRtlLayout$ | async" />
            </a>
            <span aria-hidden="true" class="fixed-space-16"></span>

            @if ((iPads$ | async)?.length) {
              <a
                class="link"
                [routerLink]="getCategoryCollectionFragment('TABLET')"
                [queryParams]="getSearchParams(null, 'Apple')"
                [class.selected]="showDropDown === 'iPads'">
                <img class="thumb" alt="iPads" [src]="sharedImages()?.ipad" />
                <h1>iPads</h1>
                <span class="dynamic-space"></span>
                <popsy-icon-arrow-right-1
                  (click)="openDropDown('iPads', $event)"
                  class="arrow"
                  [class.rtl]="isRtlLayout$ | async" />
              </a>
              <span aria-hidden="true" class="fixed-space-16"></span>
            }
            @if ((tablets$ | async)?.length) {
              <a
                class="link"
                [routerLink]="getCategoryCollectionFragment('TABLET')"
                [queryParams]="getSearchParams(null, null)"
                [class.selected]="showDropDown === 'Tablets'">
                <img class="thumb" alt="Tablets" [src]="sharedImages()?.tablet" />
                <h1>{{ t('category_tablet_title') }}</h1>
                <span class="dynamic-space"></span>
                <popsy-icon-arrow-right-1
                  (click)="openDropDown('Tablets', $event)"
                  class="arrow"
                  [class.rtl]="isRtlLayout$ | async" />
              </a>
            }
            <span aria-hidden="true" class="fixed-space-16"></span>

            @if ((watches$ | async)?.length) {
              <a
                class="link"
                [routerLink]="getCategoryCollectionFragment('WATCH')"
                [queryParams]="getSearchParams(null, null)"
                [class.selected]="showDropDown === 'Watches'">
                <img class="thumb" alt="Watches" [src]="sharedImages()?.watch" />
                <h1>{{ t('category_watch_title') }}</h1>
                <span class="dynamic-space"></span>
                <popsy-icon-arrow-right-1
                  (click)="openDropDown('Watches', $event)"
                  class="arrow"
                  [class.rtl]="isRtlLayout$ | async" />
              </a>
              <span aria-hidden="true" class="fixed-space-16"></span>
            }
            @if ((airPods$ | async)?.length) {
              <a
                class="link"
                [routerLink]="getCategoryCollectionFragment('HEADPHONES')"
                [queryParams]="getSearchParams(null, 'Apple')"
                [class.selected]="showDropDown === 'airPods'">
                <img class="thumb" alt="Watches" [src]="sharedImages()?.airpod" />
                <h1>AirPods</h1>
                <span class="dynamic-space"></span>
                <popsy-icon-arrow-right-1
                  (click)="openDropDown('airPods', $event)"
                  class="arrow"
                  [class.rtl]="isRtlLayout$ | async" />
              </a>
              <span aria-hidden="true" class="fixed-space-16"></span>
            }
            @if ((headphones$ | async)?.length) {
              <a
                class="link"
                [routerLink]="getCategoryCollectionFragment('HEADPHONES')"
                [queryParams]="getSearchParams(null, null)"
                [class.selected]="showDropDown === 'Headphones'">
                <img class="thumb" alt="Audio" [src]="sharedImages()?.audio" />
                <h1>{{ t('category_headphones_title') }}</h1>
                <span class="dynamic-space"></span>
                <popsy-icon-arrow-right-1
                  (click)="openDropDown('Headphones', $event)"
                  class="arrow"
                  [class.rtl]="isRtlLayout$ | async" />
              </a>
              <span aria-hidden="true" class="fixed-space-16"></span>
            }
            @if ((gaming$ | async)?.length) {
              <a
                class="link"
                [routerLink]="getCategoryCollectionFragment('GAMING')"
                [queryParams]="getSearchParams(null, null)"
                [class.selected]="showDropDown === 'Gaming'">
                <img class="thumb" alt="Gaming" [src]="sharedImages()?.gaming" />
                <h1>{{ t('category_gaming_title') }}</h1>
                <span class="dynamic-space"></span>
                <popsy-icon-arrow-right-1
                  (click)="openDropDown('Gaming', $event)"
                  class="arrow"
                  [class.rtl]="isRtlLayout$ | async" />
              </a>
              <span aria-hidden="true" class="fixed-space-16"></span>
            }
            @if ((accessories$ | async)?.length) {
              <a
                class="link"
                [routerLink]="getCategoryCollectionFragment('ACCESSORIES')"
                [queryParams]="getSearchParams(null, null)"
                [class.selected]="showDropDown === 'Accessories'">
                <img class="thumb" alt="Accessories" [src]="sharedImages()?.accessory" />
                <h1>{{ t('category_accessories_title') }}</h1>
                <span class="dynamic-space"></span>
                <popsy-icon-arrow-right-1
                  (click)="openDropDown('Accessories', $event)"
                  class="arrow"
                  [class.rtl]="isRtlLayout$ | async" />
              </a>
              <span aria-hidden="true" class="fixed-space-16"></span>
            }
            <a
              class="link"
              [routerLink]="getProductSearchFragment()"
              [queryParams]="getSearchParams()"
              (click)="onItemSelected()"
              [class.selected]="showDropDown === 'allProducts'">
              <h1 class="text-secondary">{{ t('label_shop_all') }}</h1>
            </a>

            @if (mobileMode) {
              <span class="fixed-space-64" aria-hidden="true"></span>
              <span class="fixed-space-64" aria-hidden="true"></span>
            }
            @if (!gridMode) {
              <span class="fixed-space-16" aria-hidden="true"></span>
            }
          </div>
        </div>
      </div>
    }

    <!-- DROPDOWNS -->
    @if (showDropDown !== null) {
      <div class="drop-down-wrapper">
        <div [class.desktop]="(biggerThan$ | async)?.mobileL" class="drop-down category">
          @if (mobileMode) {
            <a
              class="popsy-button-icon"
              [class.unread]="true"
              (click)="showDropDown = null; showLaptops = null">
              @if (!(isRtlLayout$ | async)) {
                <popsy-icon-arrow-left-1 class="icon"></popsy-icon-arrow-left-1>
              }
              @if (isRtlLayout$ | async) {
                <popsy-icon-arrow-right-1 class="icon"></popsy-icon-arrow-right-1>
              }
            </a>
          }
          <span aria-hidden="true" class="fixed-space-16"></span>
          <!-- iphones-drop-down -->
          @if (showDropDown === 'iPhones') {
            <div class="list iphones-drop-down">
              @for (product of iPhones$ | async; track trackByModel($index, product)) {
                <span
                  (click)="onItemSelected()"
                  [routerLink]="getModelCollectionFragment(product.model)"
                  [queryParams]="queryParamsToKeep$ | async"
                  >{{ product.model }}</span
                >
                <span aria-hidden="true" class="fixed-space-8"></span>
                @if (mobileMode) {
                  <span class="line"></span>
                }
                <span aria-hidden="true" class="fixed-space-8"></span>
              }
            </div>
          }
          <!-- samsung-drop-down -->
          @if (showDropDown === 'Samsung Phones') {
            <div class="list samsung-drop-down">
              @for (product of samsung$ | async; track trackByModel($index, product)) {
                <span
                  (click)="onItemSelected()"
                  [routerLink]="getModelCollectionFragment(product.model)"
                  [queryParams]="queryParamsToKeep$ | async"
                  >{{ product.model }}</span
                >
                <span aria-hidden="true" class="fixed-space-8"></span>
                @if (mobileMode) {
                  <span class="line"></span>
                }
                <span aria-hidden="true" class="fixed-space-8"></span>
              }
            </div>
          }

          <!-- samsung-drop-down -->
          @if (showDropDown === 'Smartphones') {
            <div class="list smartphones-drop-down">
              @for (
                product of smartphones$ | async;
                track trackByModel($index, product)
              ) {
                <span
                  (click)="onItemSelected()"
                  [routerLink]="getModelCollectionFragment(product.model)"
                  [queryParams]="queryParamsToKeep$ | async"
                  >{{ product.model }}</span
                >
                <span aria-hidden="true" class="fixed-space-8"></span>
                @if (mobileMode) {
                  <span class="line"></span>
                }
                <span aria-hidden="true" class="fixed-space-8"></span>
              }
            </div>
          }

          <!-- macbook-drop-down -->
          @if (showDropDown === 'macBooks') {
            <div class="list macbook-drop-down">
              @for (product of macBooks$ | async; track trackByModel($index, product)) {
                <span
                  (click)="onItemSelected()"
                  [routerLink]="getModelCollectionFragment(product.model)"
                  [queryParams]="queryParamsToKeep$ | async"
                  >{{ product.model }}</span
                >
                <span aria-hidden="true" class="fixed-space-8"></span>
                @if (mobileMode) {
                  <span class="line"></span>
                }
                <span aria-hidden="true" class="fixed-space-8"></span>
              }
            </div>
          }
          <!-- macbook-drop-down -->
          @if (showDropDown === 'macBooks') {
            <div class="list macbook-drop-down">
              @for (product of macBooks$ | async; track trackByModel($index, product)) {
                <span
                  (click)="onItemSelected()"
                  [routerLink]="getModelCollectionFragment(product.model)"
                  [queryParams]="queryParamsToKeep$ | async"
                  >{{ product.model }}</span
                >
                <span aria-hidden="true" class="fixed-space-8"></span>
                @if (mobileMode) {
                  <span class="line"></span>
                }
                <span aria-hidden="true" class="fixed-space-8"></span>
              }
            </div>
          }
          <!-- laptops-drop-down -->
          @if (showDropDown === 'Laptops') {
            <div class="list laptops-drop-down">
              @for (brand of laptopBrands$ | async; track trackByModel($index, brand)) {
                <span
                  (click)="getLaptops(brand)"
                  [class.selected]="showLaptops === brand">
                  {{ brand }}
                </span>
                <span aria-hidden="true" class="fixed-space-8"></span>
                @if (mobileMode) {
                  <span class="line"></span>
                }
                <span aria-hidden="true" class="fixed-space-8"></span>
              }
            </div>
          }
          @if (showLaptops !== null) {
            <div [class.desktop]="(biggerThan$ | async)?.mobileL" class="sub drop-down">
              @if (mobileMode) {
                <a
                  class="popsy-button-icon"
                  [class.unread]="true"
                  (click)="showLaptops = null">
                  @if (!(isRtlLayout$ | async)) {
                    <popsy-icon-arrow-left-1 class="icon"></popsy-icon-arrow-left-1>
                  }
                  @if (isRtlLayout$ | async) {
                    <popsy-icon-arrow-right-1 class="icon"></popsy-icon-arrow-right-1>
                  }
                </a>
              }
              <span aria-hidden="true" class="fixed-space-16"></span>
              @if (showLaptops !== null && showDropDown) {
                <div class="list iphones-drop-down">
                  @for (
                    product of laptops$ | async;
                    track trackByModel($index, product)
                  ) {
                    <span
                      (click)="onItemSelected()"
                      [routerLink]="getModelCollectionFragment(product.model)"
                      [queryParams]="queryParamsToKeep$ | async"
                      >{{ product.model }}</span
                    >
                    <span aria-hidden="true" class="fixed-space-8"></span>
                    @if (mobileMode) {
                      <span class="line"></span>
                    }
                    <span aria-hidden="true" class="fixed-space-8"></span>
                  }
                </div>
              }
              <span aria-hidden="true" class="fixed-space-24"></span>
              @if (mobileMode) {
                <span aria-hidden="true" class="fixed-space-24"></span>
              }
            </div>
          }
          <!-- ipads-drop-down -->
          @if (showDropDown === 'iPads') {
            <div class="list ipads-drop-down">
              @for (product of iPads$ | async; track trackByModel($index, product)) {
                <span
                  (click)="onItemSelected()"
                  [routerLink]="getModelCollectionFragment(product.model)"
                  [queryParams]="queryParamsToKeep$ | async"
                  >{{ product.model }}</span
                >
                <span aria-hidden="true" class="fixed-space-8"></span>
                @if (mobileMode) {
                  <span class="line"></span>
                }
                <span aria-hidden="true" class="fixed-space-8"></span>
              }
            </div>
          }
          <!-- tablets-drop-down -->
          @if (showDropDown === 'Tablets') {
            <div class="list tablets-drop-down">
              @for (product of tablets$ | async; track trackByModel($index, product)) {
                <span
                  (click)="onItemSelected()"
                  [routerLink]="getModelCollectionFragment(product.model)"
                  [queryParams]="queryParamsToKeep$ | async"
                  >{{ product.model }}</span
                >
                <span aria-hidden="true" class="fixed-space-8"></span>
                @if (mobileMode) {
                  <span class="line"></span>
                }
                <span aria-hidden="true" class="fixed-space-8"></span>
              }
            </div>
          }
          <!-- watches-drop-down -->
          @if (showDropDown === 'Watches') {
            <div class="list watches-drop-down">
              @for (product of watches$ | async; track trackByModel($index, product)) {
                <span
                  (click)="onItemSelected()"
                  [routerLink]="getModelCollectionFragment(product.model)"
                  [queryParams]="queryParamsToKeep$ | async"
                  >{{ product.model }}</span
                >
                <span aria-hidden="true" class="fixed-space-8"></span>
                @if (mobileMode) {
                  <span class="line"></span>
                }
                <span aria-hidden="true" class="fixed-space-8"></span>
              }
            </div>
          }
          <!-- airpods-drop-down -->
          @if (showDropDown === 'airPods') {
            <div class="list airpods-drop-down">
              @for (product of airPods$ | async; track trackByModel($index, product)) {
                <span
                  (click)="onItemSelected()"
                  [routerLink]="getModelCollectionFragment(product.model)"
                  [queryParams]="queryParamsToKeep$ | async"
                  >{{ product.model }}</span
                >
                <span aria-hidden="true" class="fixed-space-8"></span>
                @if (mobileMode) {
                  <span class="line"></span>
                }
                <span aria-hidden="true" class="fixed-space-8"></span>
              }
            </div>
          }
          <!-- headphones-drop-down -->
          @if (showDropDown === 'Headphones') {
            <div class="list earbuds-drop-down">
              @for (product of headphones$ | async; track trackByModel($index, product)) {
                <span
                  (click)="onItemSelected()"
                  [routerLink]="getModelCollectionFragment(product.model)"
                  [queryParams]="queryParamsToKeep$ | async"
                  >{{ product.model }}</span
                >
                <span aria-hidden="true" class="fixed-space-8"></span>
                @if (mobileMode) {
                  <span class="line"></span>
                }
                <span aria-hidden="true" class="fixed-space-8"></span>
              }
            </div>
          }
          <!-- gaming-drop-down -->
          @if (showDropDown === 'Gaming') {
            <div class="list earbuds-drop-down">
              @for (product of gaming$ | async; track trackByModel($index, product)) {
                <span
                  (click)="onItemSelected()"
                  [routerLink]="getModelCollectionFragment(product.model)"
                  [queryParams]="queryParamsToKeep$ | async"
                  >{{ product.model }}</span
                >
                <span aria-hidden="true" class="fixed-space-8"></span>
                @if (mobileMode) {
                  <span class="line"></span>
                }
                <span aria-hidden="true" class="fixed-space-8"></span>
              }
            </div>
          }
          <!-- accessories-drop-down -->
          @if (showDropDown === 'Accessories') {
            <div class="list accessories-drop-down">
              @for (
                product of accessories$ | async;
                track trackByModel($index, product)
              ) {
                <span
                  (click)="onItemSelected()"
                  [routerLink]="getModelCollectionFragment(product.model)"
                  [queryParams]="queryParamsToKeep$ | async"
                  >{{ product.model }}</span
                >
                <span aria-hidden="true" class="fixed-space-8"></span>
                @if (mobileMode) {
                  <span class="line"></span>
                }
                <span aria-hidden="true" class="fixed-space-8"></span>
              }
            </div>
          }
          <span aria-hidden="true" class="fixed-space-24"></span>
        </div>
      </div>
    }

    <!-- DARK OVERLAY-BACKGROUND -->
    @if (showAllProducts || showDropDown !== null) {
      <div class="dark-overlay" (click)="closeDropDown()"></div>
    }
  </section>
</ng-container>
