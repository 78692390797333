import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AkitaScreenQuery } from '@app/akita/screen/state/screen.query';
import { ScreenSizeInfoState } from '@app/akita/screen/state/screen.store';
import { Observable } from 'rxjs';
import { AsyncPipe } from '@angular/common';

import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { TradeIcon1IconComponent } from '../../../shared/components/icons/generated/trade-icon-1/trade-icon-1.component';
import { TradeIcon2IconComponent } from '../../../shared/components/icons/generated/trade-icon-2/trade-icon-2.component';
import { TradeIcon3IconComponent } from '../../../shared/components/icons/generated/trade-icon-3/trade-icon-3.component';

export const WARRANTY_MONTHS = '12';

@Component({
  selector: 'app-buyback-highlights',
  templateUrl: './buyback-highlights.template.html',
  styleUrls: ['./buyback-highlights.style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TranslocoModule,
    AsyncPipe,
    TradeIcon1IconComponent,
    TradeIcon2IconComponent,
    TradeIcon3IconComponent,
  ],
})
export class BuybackHighlightsComponent {
  @Input() public country?: string | null;
  @Input() public darkMode?: boolean | null = false;
  @Input() public blueMode?: boolean | null = false;
  @Input() public centerAlign?: boolean | null = false;

  public readonly biggerThan$: Observable<ScreenSizeInfoState>;
  public language: string = this.translocoService.getActiveLang();

  public months: string;

  constructor(
    private readonly akitaScreenQuery: AkitaScreenQuery,
    private readonly translocoService: TranslocoService
  ) {
    this.biggerThan$ = this.akitaScreenQuery.selectBiggerThan();
    this.months = WARRANTY_MONTHS;
  }

  public onShowMoreInfo(): boolean {
    return false;
  }
}
