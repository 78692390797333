import { Injectable, NgZone } from '@angular/core';
import { FirebaseService } from '@app/akita/firebase/services/firebase.service';
import { environment } from '@environments/environment';
import { WindowUtils } from '@app/shared/utils/window.util';
import { UserModel } from '@app/shared/models/api/user.model';
import { FacebookPixelService } from './facebook-pixel.service';
import { AkitaRouterQuery } from '@app/akita/router/state/router.query';
import { Params } from '@angular/router';
import { LogFn } from './google-analytics.lazy';
import {
  analyticsLogEvent,
  analyticsSetCurrentScreen,
  analyticsSetUserProperties,
} from '@app/shared/firebase/firebase.wrapper';
import { Product } from '@app/akita/api/products/models/product.model';
import { AkitaCheckoutStore } from '@app/akita/api/checkout/state/checkout.store';

/**
 * Class to handle google analytics
 */
@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  public disabled: boolean;

  constructor(
    private readonly zone: NgZone,
    private readonly firebaseService: FirebaseService,
    private readonly facebookPixelService: FacebookPixelService,
    private readonly akitaRouterQuery: AkitaRouterQuery,
    private readonly akitaCheckoutStore: AkitaCheckoutStore
  ) {
    // this.disabled = environment.enableAnalitycs === false;
    this.disabled = false;
  }

  private intLogSmartlookEvent(
    eventID: string,
    param1?: any | null,
    param2?: any | null
  ): void {
    const windowRef: any | null = WindowUtils.window;
    if (windowRef && windowRef.smartlook) {
      windowRef.smartlook(eventID, param1, param2);
    }
  }

  public get logEventWrapper(): LogFn {
    return this.intLogEventWrapper.bind(this);
  }

  private intLogEventWrapper(
    eventName: string,
    eventParams: Params,
    category: string,
    label: string,
    options?: any | null
  ): void {
    this.zone.runOutsideAngular(() => {
      if (this.akitaRouterQuery.isBrowser) {
        const finalParams = this.propagateCampaignInfo(eventParams);
        if (environment.debugAnalitycs) {
          console.log(`${eventName}`, finalParams);

          if (this.disabled) {
            console.warn('Skipped! -> Firebase Analytics Disabled!!');
          }
        }

        if (!this.disabled) {
          this.firebaseService.analytics
            .then((analytics: any | null) => {
              if (analytics) {
                analyticsLogEvent(
                  analytics,
                  eventName,
                  finalParams,
                  options || undefined
                );
              }
            })
            .catch(() => {
              // Google Analytics not available atm
            });

          this.firebaseService.logEventToAnalytics(
            eventName,
            finalParams,
            category,
            label
          );

          this.logSmartlookEvent('track', eventName, finalParams);
        }
      }
    });
  }

  private propagateCampaignInfo(eventParams: Params): Params {
    const finalParams = {
      ...eventParams,
    };
    const googleADSMetadata = this.akitaRouterQuery.googleADSMetadata;
    if (googleADSMetadata.utmSource) {
      finalParams['utm_source'] = googleADSMetadata.utmSource;
    }

    if (googleADSMetadata.utmMedium) {
      finalParams['utm_medium'] = googleADSMetadata.utmMedium;
    }

    if (googleADSMetadata.utmCampaign) {
      finalParams['utm_campaign'] = googleADSMetadata.utmCampaign;
    }

    if (googleADSMetadata.utmContent) {
      finalParams['utm_content'] = googleADSMetadata.utmContent;
    }

    if (googleADSMetadata.utmContent) {
      finalParams['utm_term'] = googleADSMetadata.utmTerm;
    }

    if (googleADSMetadata.gclid) {
      finalParams['gclid'] = googleADSMetadata.gclid;
    }

    if (googleADSMetadata.dclid) {
      finalParams['dclid'] = googleADSMetadata.dclid;
    }

    if (googleADSMetadata.aclid) {
      finalParams['aclid'] = googleADSMetadata.aclid;
    }

    if (googleADSMetadata.anid) {
      finalParams['anid'] = googleADSMetadata.anid;
    }

    if (googleADSMetadata.cp1) {
      finalParams['cp1'] = googleADSMetadata.cp1;
    }

    if (googleADSMetadata.gclsrc) {
      finalParams['gclsrc'] = googleADSMetadata.gclsrc;
    }

    this.firebaseService.updateConfig({
      firebase_campaign: {
        source: googleADSMetadata.utmSource || undefined,
        medium: googleADSMetadata.utmMedium || undefined,
        campaign: googleADSMetadata.utmCampaign || undefined,
        name: googleADSMetadata.utmCampaign || undefined,
        term: googleADSMetadata.utmTerm || undefined,
        content: googleADSMetadata.utmContent || undefined,
        gclid: googleADSMetadata.gclid || undefined,
        dclid: googleADSMetadata.dclid || undefined,
        aclid: googleADSMetadata.aclid || undefined,
        anid: googleADSMetadata.anid || undefined,
        cp1: googleADSMetadata.cp1 || undefined,
        gclsrc: googleADSMetadata.gclsrc || undefined,
      },
    });

    return finalParams;
  }

  private setCurrentScreenWrapper(screenName: string, options?: any | null): void {
    this.zone.runOutsideAngular(() => {
      if (environment.debugAnalitycs) {
        console.log(`Set Current Screen to: "${screenName}"`);

        if (this.disabled) {
          console.warn('Skipped! -> Firebase Analytics Disabled!!');
        }
      }

      if (!this.disabled) {
        this.firebaseService.analytics
          .then((analytics: any | null) => {
            if (analytics) {
              analyticsSetCurrentScreen(analytics, screenName, options || undefined);
            }
          })
          .catch(() => {
            // Google Analytics not available atm
          });
      }
    });
  }

  private setUserPropertiesWrapper(properties: any, options?: any | null): void {
    this.zone.runOutsideAngular(() => {
      if (environment.debugAnalitycs) {
        console.log(`Set User Properties"`, properties);

        if (this.disabled) {
          console.warn('Skipped! -> Firebase Analytics Disabled!!');
        }
      }

      if (!this.disabled) {
        this.firebaseService.analytics
          .then((analytics: any | null) => {
            if (analytics) {
              analyticsSetUserProperties(analytics, properties, options || undefined);
            }
          })
          .catch(() => {
            // Google Analytics not available atm
          });
      }
    });
  }

  public get recordPageView(): (
    name: string,
    view: string,
    path?: string | null
  ) => void {
    return (name: string, view: string, path?: string | null) => {
      this.pageView(name, view, path);
    };
  }

  public get logSmartlookEvent(): (
    eventID: string,
    param1?: any | null,
    param2?: any | null
  ) => void {
    return (eventID: string, param1?: any | null, param2?: any | null) => {
      this.intLogSmartlookEvent(eventID, param1, param2);
    };
  }

  public get firebaseRecordPaymentMethodSelectedADConversion(): () => void {
    return () => {
      this.firebaseService.recordPaymentMethodSelectedADConversion();
    };
  }

  public get firebaseRecordPurchaseADConversion(): (deliveryId: string) => void {
    return (deliveryId: string) => {
      this.firebaseService.recordPurchaseADConversion(deliveryId);
    };
  }

  public get firebaseRecordAddressAndPhoneADConversion(): () => void {
    return () => {
      this.firebaseService.recordAddressAndPhoneADConversion();
    };
  }

  public get firebaseRecordProductPageViewADConversion(): () => void {
    return () => {
      this.firebaseService.recordProductPageViewADConversion();
    };
  }

  public get firebaseRecordAddToCartADConversion(): () => void {
    return () => {
      this.firebaseService.recordAddToCartADConversion();
    };
  }

  public get firebaseSetCurrentScreenWrapper(): (
    screenName: string,
    options?: any | null
  ) => void {
    return (screenName: string, options?: any | null) => {
      this.setCurrentScreenWrapper(screenName, options);
    };
  }

  public get facebookTrackAddToCart(): (product: Product) => void {
    return (product: Product) => {
      this.facebookPixelService.trackAddToCart(product);
    };
  }

  public get facebookTrackInitiateCheckout(): (products: Array<Product>) => void {
    return (products: Array<Product>) => {
      this.facebookPixelService.trackInitiateCheckout(products);
    };
  }

  public get facebookTrackViewItem(): (products: Product) => void {
    return (product: Product) => {
      this.facebookPixelService.trackViewContent(product);
    };
  }

  public get facebookTrackSearch(): (query: string, categoryId: string) => void {
    return (query: string, categoryId: string) => {
      this.facebookPixelService.trackSearch(query, categoryId);
    };
  }

  public get facebookTrackAddPaymentInfo(): (
    id: string | Array<string>,
    amount: number,
    currency: string
  ) => void {
    return (id: string | Array<string>, amount: number, currency: string) => {
      this.facebookPixelService.trackAddPaymentInfo(id, amount, currency);
    };
  }

  public get facebookTrackPurchase(): (
    products: Array<Product>,
    popsyFee: number,
    currency: string
  ) => void {
    return (products: Array<Product>, popsyFee: number, currency: string) => {
      this.facebookPixelService.trackPurchase(products, popsyFee, currency);
    };
  }

  public get auth(): (eventName: string, user: UserModel | null) => void {
    return (eventName: string, user: UserModel | null) => {
      this.intAuth(eventName, user);
    };
  }

  public pageView(name: string, view: string, path?: string | null): void {
    this.logEventWrapper(
      'page_view',
      {
        page_name: `${name || 'Home'}`,
        page_title: `${name || 'Home'}`,
        page_location: `${WindowUtils.getLocationHref() || 'https://www.popsy.app'}`,
        page_path: path || `${WindowUtils.getLocationPathname() || '/'}`,
      },
      '',
      ''
    );
    this.logEventWrapper(
      'screen_view',
      {
        app_name: 'Web',
        screen_name: `${name || 'Home'}`,
        screen_class: `${view || 'search'}`,

        firebase_screen: `${view || 'Home'}`,
        firebase_screen_class: `${view || 'search'}`,
        firebase_screen_id: `${view || 'search'}`,
      },
      '',
      ''
    );
    this.facebookPixelService.trackPageView();

    this.logSmartlookEvent(
      'navigation',
      `${path || WindowUtils.getLocationPathname() || '/'}`
    );
  }

  private intAuth(eventName: string, user: UserModel | null): void {
    let userId = '';
    let username = '';
    let email = '';
    if (user) {
      userId = user.id || '';
      username = user.firstName || '';
      email = user.email || '';
    }

    this.firebaseService.setUserId(userId || null);

    this.logEventWrapper(
      eventName,
      {
        object_id: userId,
        username: username,
        email: email,
      },
      'engagement',
      'method'
    );

    this.setUserPropertiesWrapper({
      user_id: userId,
      user_uid: userId,
      user_registration: userId ? 'registered' : 'unregistered',
    });

    this.firebaseService.setUserId(userId || null);

    this.facebookPixelService.trackCompleteRegistration(username, Boolean(userId));

    if (
      eventName === 'signup_facebook' ||
      eventName === 'signup_google' ||
      eventName === 'signup_apple' ||
      eventName === 'signup_email'
    ) {
      this.facebookPixelService.trackLead(eventName, username);
    }

    this.logSmartlookEvent('identify', `${userId || ''}`, {
      name: `${username || ''}`,
      email: `${email || ''}`,
    });
  }

  public refreshUserInfo(
    user: UserModel | null,
    numberOfListings?: number | null,
    numberOfMessages?: number | null,
    numberOfFavorites?: number | null
  ): void {
    let userId = '';
    if (user) {
      userId = user.id || '';
    }

    this.setUserPropertiesWrapper({
      user_id: userId,
      user_uid: userId,
      user_listings: numberOfListings || 0,
      user_messages: numberOfMessages || 0,
      user_favorites: numberOfFavorites || 0,
      user_registration: userId ? 'registered' : 'unregistered',
      experiment_cep_address_form: 'cep',
    });

    this.firebaseService.setUserId(userId || null);

    this.setTidioVisitorData(
      user?.id,
      user?.firstName,
      user?.phone,
      user?.email,
      user?.location?.city,
      user?.location?.country,
      [userId ? 'registered' : 'unregistered']
    );
  }

  public setTidioVisitorData(
    id?: string | null,
    name?: string | null,
    phone?: string | null,
    email?: string | null,
    city?: string | null,
    country?: string | null,
    tags?: Array<string> | null
  ): void {
    if (this.akitaRouterQuery.isBrowser) {
      const userInfo = {
        ...((window as any).tidioIdentify || {}),
      };

      if (id) {
        userInfo.distinct_id = id;
      }

      if (name) {
        userInfo.name = name;
      }

      if (email) {
        userInfo.email = email;
      }

      if (phone) {
        userInfo.phone = phone;
      }

      if (city) {
        userInfo.city = city;
      }

      if (country) {
        userInfo.country = `${country}`.toUpperCase();
      }

      if (tags) {
        const newTags = [...(userInfo.tags || [])];
        for (const tag of tags) {
          if (!newTags.includes(tag)) {
            newTags.push(tag);
          }
        }
        userInfo.tags = newTags;
      }

      try {
        (window as any).tidioIdentify = userInfo;
        if ((window as any)?.tidioChatApi?.setVisitorData) {
          (window as any).tidioChatApi.setVisitorData(userInfo);
        }
      } catch (err) {}
    }
  }

  public appException(
    error: string,
    errorType: 'network' | 'application' | 'other',
    errorCode: string | null,
    isFatal?: boolean | null,
    screenId?: string | null,
    eventOrigin?: string | null
  ): void {
    this.logEventWrapper(
      'exception',
      {
        description: error,
        errorType: errorType,
        code: errorCode,
        fatal: isFatal === true,
        firebase_event_origin: eventOrigin || undefined,
        firebase_screen_id: screenId || undefined,
      },
      'application',
      'errors'
    );
    this.logEventWrapper(
      'app_exception',
      {
        description: error,
        errorType: errorType,
        code: errorCode,
        fatal: isFatal === true,
        firebase_event_origin: eventOrigin || undefined,
        firebase_screen_id: screenId || undefined,
      },
      'application',
      'errors'
    );

    this.logSmartlookEvent('error', {
      description: error,
      errorType: errorType,
      code: errorCode,
      fatal: isFatal === true,
      firebase_event_origin: eventOrigin || undefined,
      firebase_screen_id: screenId || undefined,
    });
  }

  public setSessionUserDetailsIntoDataLayer(): void {
    if (this.akitaRouterQuery.isBrowser) {
      const session = this.akitaCheckoutStore.getValue().session;
      try {
        // send an unique event ID both for Pixel and GTM / Convertions API
        const email = {
          EC_email:
            session?.email ||
            (window as any).tidioIdentify?.email ||
            'email_not@popsy.com',
        };
        const name = {
          EC_name: session?.userName || (window as any).tidioIdentify?.name || 'noname',
        };
        const phoneNumber = {
          EC_phoneNumber:
            session?.phone?.display ||
            (window as any).tidioIdentify?.phone ||
            'xxx xxxxxxxx',
        };

        (window as any).dataLayer.push(email);
        (window as any).dataLayer.push(name);
        (window as any).dataLayer.push(phoneNumber);
      } catch (error) {
        // Accessing window will throw error when using in Server Side
      }
    }
  }

  public setUserProperties(properties: any, options?: any | null): void {
    this.setUserPropertiesWrapper(properties, options);
  }
}
