import { StoreConfig, EntityState, EntityStore } from '@datorama/akita';
import { UserModel } from '@app/shared/models/api/user.model';
import { Injectable } from '@angular/core';
import { ApiError, parseApiError } from '@app/shared/models/api/api-error.model';

export interface AkitaUsersState extends EntityState<UserModel> {
  updatingUserInformation: boolean;
  errorUpdatingUserInformation: ApiError | null;
  updatingUserLocale: boolean;
  updateUserLocaleError: any | null;

  verifyingSMS: boolean;
  errorVerifyingSMS: ApiError | null;
}

export function createInitialState(): AkitaUsersState {
  return {
    updatingUserInformation: false,
    errorUpdatingUserInformation: null,
    updatingUserLocale: false,
    updateUserLocaleError: null,
    verifyingSMS: false,
    errorVerifyingSMS: null,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'users', resettable: true })
export class AkitaUsersStore extends EntityStore<AkitaUsersState> {
  constructor() {
    super(createInitialState());
  }

  public toggleUpdatingUserLocale(isUpdating?: boolean | null): void {
    this.update({
      updatingUserLocale: Boolean(isUpdating),
    });
  }

  public setUpdateUserLocaleError(error: unknown): void {
    this.update({
      updateUserLocaleError: error || null,
    });
  }

  public toggleUpdatingUserInformation(isUpdating?: boolean | null): void {
    this.update({
      updatingUserInformation: Boolean(isUpdating),
    });
  }

  public setUpdateUserInformationError(error: unknown): void {
    this.update({
      errorUpdatingUserInformation: error ? parseApiError(error) : null,
    });
  }

  public addUserInfo(user?: UserModel | null): void {
    const parsedUser = UserModel.fromJson(user);
    if (parsedUser) {
      this.upsertMany([
        {
          ...parsedUser,
          cachedOn: new Date(),
        },
      ]);
    }
  }

  public setUserInfo(user?: UserModel | null): void {
    this.update({
      user: {
        ...(user || null),
        cachedOn: new Date(),
      },
    });
  }

  public toggleVerifyingSMS(isVerifying?: boolean | null): void {
    this.update({
      verifyingSMS: Boolean(isVerifying),
    });
  }

  public setVerifyingSMSError(error: unknown): void {
    this.update({
      errorVerifyingSMS: error ? parseApiError(error) : null,
    });
  }
}
