import { PriceModel } from '@app/shared/models/api/price.model';

export const SUPPORTED_WALLET_CURRENCIES = ['SAR', 'AED'];

export interface WalletEntry {
  id: string;
  amount: PriceModel;
  createdAt: Date;
  description: string;
  referenceId: string;
  type: string;
  open?: boolean;
}

export class UserWallet {
  public balance: PriceModel | null;
  public entries: Array<WalletEntry>;

  constructor() {
    this.balance = new PriceModel();
    this.entries = [];
  }

  public static fromJson(data?: Record<string, any> | null): UserWallet {
    const wallet = new UserWallet();
    wallet.balance = PriceModel.fromJson({
      amount: data?.balance,
      currency: data?.currency,
    });
    wallet.entries = this.fromJsonEntries(data?.entries || []);
    return wallet;
  }

  public static fromJsonEntries(data?: Record<string, any> | null): Array<WalletEntry> {
    return (
      data?.map((entry: any) => ({
        id: entry.id,
        amount: PriceModel.fromJson({
          amount: entry.amount,
          currency: entry.currency,
        }),
        createdAt: new Date(entry.createdAt),
        description: entry.description,
        referenceId: entry.referenceId,
        type: entry.type,
        open: false,
      })) || []
    );
  }
}
