import { StoreConfig, Store } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { RemoteConfigModel } from '../models/remote-config.model';
import { DeliveryEstimate } from '../models/delivery-estimate.model';
import { SharedImageCategory } from '../models/shared-category-images.model';
import { NewsletterSubtitle } from '../models/newsletter-subtitle.model';
import { CarouselBannerSlide } from '../models/carousel-banner-slide.model';
import { PopularDevices } from '../models/popular-devices.model';

export const DEFAULT_DELIVERY_ESTIMATES = [
  {
    country: 'BR',
    values: { minDays: 7, maxDays: 21 },
  },
  {
    country: 'US',
    values: { minDays: 7, maxDays: 7 },
  },
  {
    country: 'SA',
    values: { minDays: 5, maxDays: 7 },
  },
  {
    country: 'AE',
    values: { minDays: 5, maxDays: 7 },
  },
];

export interface AkitaRemoteConfigState {
  featureAppUpdate: boolean; // Flag to check in-app updates on app start.

  deliveryEstimates: Array<DeliveryEstimate>;

  expressCheckout: boolean | null;
  addressSearch: boolean;
  popularDevices: PopularDevices | null;

  carouselBannerSlides: Array<CarouselBannerSlide> | null;

  testProductPage: string;
  sharedImageCategory: SharedImageCategory | null;
  newsletterSubtitle: NewsletterSubtitle | null;
  announcementBarText: NewsletterSubtitle | null;

  sendWebReviewsTo: string | null;

  enableBuybackFeature: boolean;
  sortedPaymentMethods: Array<string> | null;

  isLoading: boolean;
  error: any | null;
}

export function createInitialState(): AkitaRemoteConfigState {
  return {
    featureAppUpdate: false,

    deliveryEstimates: DEFAULT_DELIVERY_ESTIMATES,

    isLoading: false,
    error: null,
    popularDevices: null,

    expressCheckout: null,

    sharedImageCategory: null,
    newsletterSubtitle: null,
    announcementBarText: null,
    addressSearch: true,
    testProductPage: 'original',
    sendWebReviewsTo: null,
    enableBuybackFeature: false,
    carouselBannerSlides: null,
    sortedPaymentMethods: null,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'remote-config', resettable: true })
export class AkitaRemoteConfigStore extends Store<AkitaRemoteConfigState> {
  constructor() {
    super(createInitialState());
  }

  public updateConfig(config: Partial<RemoteConfigModel>): void {
    this.update({
      deliveryEstimates: DEFAULT_DELIVERY_ESTIMATES,
      featureAppUpdate: config.featureAppUpdate,
      sharedImageCategory: config.sharedImageCategory,
      popularDevices: config.popularDevices,
      newsletterSubtitle: config.newsletterSubtitle,
      announcementBarText: config.announcementBarText,
      testProductPage: config.testProductPage || 'original',
      sendWebReviewsTo: config.sendWebReviewsTo,
      enableBuybackFeature: config.enableBuybackFeature,
      carouselBannerSlides: config.carouselBannerSlides,
      expressCheckout: config.expressCheckout,
      addressSearch: config.addressSearch,
      sortedPaymentMethods: config.sortedPaymentMethods,
    });
  }

  public updateAddressSearch(addressSearch: boolean): void {
    this.update({
      addressSearch: addressSearch,
    });
  }

  public updateExpressCheckout(experimentCheckout: boolean | null): void {
    this.update({
      expressCheckout: experimentCheckout,
    });
  }

  public setLoading(isLoading: boolean): void {
    this.update({
      isLoading: isLoading,
    });
  }

  public setError(error: unknown): void {
    this.update({
      error: error,
    });
  }
}
