/* eslint-disable @typescript-eslint/no-magic-numbers */
import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { PriceModel } from '../models/api/price.model';
import { AkitaConfigurationQuery } from '@app/akita/configuration/state/configuration.query';

@Injectable({
  providedIn: 'root',
})
export class TransformAmountService {
  constructor(private readonly akitaConfigurationQuery: AkitaConfigurationQuery) {}

  public transform(price?: PriceModel | number | null, currency?: string): string {
    let output = '';
    if (price !== null && price !== undefined) {
      if (typeof price === 'number') {
        price = PriceModel.fromJson({
          amount: price,
        });
      }

      const parsedAmount = price ? price.amount : 0;
      let parsedCurrency = `${
        currency || (price ? price.currency : null) || 'USD'
      }`.toUpperCase();

      // Special case for Saudi Riyal
      // Symbol 0xe900 located at @font-face CurrencySymbol
      if (parsedCurrency === 'SAR') {
        parsedCurrency = String.fromCharCode(0xe900);
      }

      try {
        // Hardcoded Syntax to the en-US version to match the current design
        let locale = this.akitaConfigurationQuery.locale;
        // We show Arabic currencies in western format
        if (locale === 'ar-SA') {
          locale = 'en-US';
        }
        const formatter = new Intl.NumberFormat(locale || 'en-US', {
          style: 'currency',
          currency: parsedCurrency,
          minimumFractionDigits: 0,
        });
        output = formatter.format(parsedAmount);
      } catch (error) {
        const formattedAmount = new Intl.NumberFormat('en-US', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        }).format(parsedAmount);
        // Intl.NumberFormat not supported
        output = `${parsedCurrency}${formattedAmount} `;
      }
    }

    return output;
  }
}

@Pipe({
  name: 'amount',
  standalone: true,
})
export class AmountPipe implements PipeTransform {
  constructor(private readonly transformAmountService: TransformAmountService) {}

  /**
   * Gets a formatted string with the amount
   * @param {PriceModel} price the price object
   * @param {string} currency the currency to use (overwrites the one in PriceModel)
   * @returns {String} the formatted amount.
   */
  public transform(price?: PriceModel | number | null, currency?: string): string {
    return this.transformAmountService.transform(price, currency);
  }
}
