import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { AkitaRouterQuery } from '@app/akita/router/state/router.query';
import { Product } from '@app/akita/api/products/models/product.model';
import { UUID } from '@app/shared/utils/uuid.util';

/**
 * Class to handle facebook pixel events
 */
@Injectable({
  providedIn: 'root',
})
export class FacebookPixelService {
  public isBrowser: boolean;
  public disabled: boolean;

  public fetchingIpAddress: boolean;

  constructor(private readonly akitaRouterQuery: AkitaRouterQuery) {
    this.isBrowser = this.akitaRouterQuery.isBrowser;
    this.disabled = false;
    this.fetchingIpAddress = false;
  }

  public get facebookPixel(): any {
    if (environment.debugAnalitycs) {
      return function (...args: Array<any>): void {
        const argumentsList = new Array(0);
        for (const key of Object.keys(args || {})) {
          const arg = args[key as any];
          if (typeof arg === 'string') {
            argumentsList.push(arg);
          } else {
            argumentsList.push(JSON.stringify(arg, null, '  '));
          }
        }
        console.log(`[ Facebook Pixel ]\t${argumentsList.join('\t')}`);
      };
    }

    if (this.isBrowser && environment.enableAnalitycs) {
      return (window as any).fbq || function (): void {};
    }

    return function (): void {};
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public trackEvent(eventName: string, options?: any): void {
    if (!this.disabled) {
      try {
        // get IP address and push it to the dataLayer
        this.pushIpAddressToDataLayer();

        // send an unique event ID both for Pixel and GTM / Convertions API
        const eventID = { eventID: 'FB_' + eventName + '_' + UUID.generate() };
        const email = {
          email: (window as any).tidioIdentify?.email || 'email_not@popsy.com',
        };
        const phoneNumber = {
          phoneNumber: (window as any).tidioIdentify?.phone || '',
        };
        const name = {
          name: (window as any).tidioIdentify?.name || 'noname',
        };

        const fbOptions = {
          fbOptions: options || {},
        };

        (window as any).dataLayer.push(eventID);
        (window as any).dataLayer.push(email);
        (window as any).dataLayer.push(name);
        (window as any).dataLayer.push(phoneNumber);
        (window as any).dataLayer.push(fbOptions);

        this.facebookPixel('track', eventName, options || undefined, eventID);
      } catch (error) {
        // Accessing window will throw error when using in Server Side
      }
    }
  }

  /**
   *
   * When payment information is added in the checkout flow.
   * Example: A person clicks on a save billing information button.
   *
   * @param id
   * @param amount
   * @param currency
   */
  public trackAddPaymentInfo(
    id: string | Array<string>,
    amount: number,
    currency: string
  ): void {
    this.trackEvent('AddPaymentInfo', {
      content_ids: [id],
      contents: [{ id: id, quantity: 1 }],
      currency: currency,
      value: amount,
    });
  }

  /**
   * When a product is added to the shopping cart.
   * Example: A person clicks on an add to cart button.
   * @param product
   */
  public trackAddToCart(product: Product): void {
    this.trackEvent('AddToCart', {
      content_ids: [product.id],
      content_name: product.title,
      content_type: 'product',
      contents: [{ id: product.id, quantity: 1 }],
      currency: product.price.currency,
      value: product.price.amount,
    });
  }

  /**
   * When a product is added to a wishlist.
   * Example: A person clicks on an add to wishlist button.
   * @param product
   */
  public trackAddToWishlist(product: Product): void {
    this.trackEvent('AddToWishlist', {
      content_name: product.title,
      content_category: product.category,
      content_ids: [product.id],
      contents: [{ id: product.id, quantity: 1 }],
      currency: product.price.currency,
      value: product.price.amount,
    });
  }

  /**
   * When a registration form is completed.
   * Example: A person submits a completed subscription or signup form.
   *
   * @param username
   * @param success
   */
  public trackCompleteRegistration(username: string, success: boolean): void {
    this.trackEvent('CompleteRegistration', {
      content_name: username,
      status: success,
      currency: 'USD',
      value: 1,
    });
  }

  /**
   * When a person initiates contact with your business via telephone, SMS, email, chat, etc.
   * Example: A person submits a question about a product.
   */
  public trackContact(): void {
    this.trackEvent('Contact');
  }

  /**
   * When a person enters the checkout flow prior to completing the checkout flow.
   * Example: A person clicks on a checkout button.
   *
   * @param product
   */
  public trackInitiateCheckout(products: Array<Product>): void {
    this.trackEvent('InitiateCheckout', {
      content_category: products.map((x) => x.category),
      content_ids: products.map((x) => x.id),
      contents: products.map(
        (x) =>
          <any>{
            id: x.id,
            quantity: x.quantity,
          }
      ),
      currency: products[0].price.currency,
      num_items: products.reduce((total, current) => total + (current?.quantity || 1), 0),
      value: products.reduce(
        (total, current) => total + current?.price.amount * (current?.quantity || 1),
        0
      ),
    });
  }

  /**
   * When a sign up is completed.
   * Example: A person clicks on pricing.
   *
   * @param category
   * @param name
   * @param currency
   * @param value
   */
  public trackLead(
    category: string,
    name: string,
    currency?: string,
    value?: number
  ): void {
    this.trackEvent('Lead', {
      content_category: category,
      content_name: name,
      currency,
      value: value,
    });
  }

  /**
   * This is the default pixel tracking page visits.
   * Example: A person lands on your website pages.
   */
  public trackPageView(): void {
    this.trackEvent('PageView');
  }

  /**
   * When a purchase is made or checkout flow is completed.
   * Example: A person has finished the purchase or checkout flow and lands on thank you or confirmation page.
   *
   * @param product
   * @param popsyFee
   * @param currency
   */
  public trackPurchase(
    products: Array<Product>,
    popsyFee: number,
    currency: string
  ): void {
    this.trackEvent('Purchase', {
      content_ids: products.map((x) => x.id),
      content_name: products.map((x) => x.title),
      content_type: 'product',
      contents: products.map(
        (x) =>
          <any>{
            id: x.id,
            quantity: x.quantity,
          }
      ),
      currency: currency,
      num_items: products.reduce((total, current) => total + (current?.quantity || 1), 0),
      value: popsyFee,
    });
  }

  /**
   * When a search is made.
   * Example: A person searches for a product on your website.
   *
   * @param query
   * @param category
   */
  public trackSearch(query: string, category?: string): void {
    this.trackEvent('Search', {
      content_category: category,
      search_string: query,
    });
  }

  /**
   * A visit to a web page you care about (for example, a product page or landing page).
   * ViewContent tells you if someone visits a web page's URL, but not what they see or do on that page.
   * Example: A person lands on a product details page.
   * @param product
   */
  public trackViewContent(product: Product): void {
    this.trackEvent('ViewContent', {
      content_ids: [product.id],
      content_name: product.title,
      currency: product.price.currency,
      value: product.price.amount,
      content_category: product.category,
      content_type: 'product',
      contents: [{ id: product.id, quantity: 1 }],
    });
  }

  /**
   * Fetch the User IP Address using jsonip.com
   * and push it to the DataLayer to be sent with the
   * Purchase Event
   */
  private pushIpAddressToDataLayer(): void {
    try {
      if (
        !(window as any).dataLayer.some((x: any) => x.ipAddress) &&
        !this.fetchingIpAddress
      ) {
        this.fetchingIpAddress = true;
        const subsc = fetch('https://jsonip.com', { mode: 'cors' })
          .then((resp) => resp.json())
          .then((data) => {
            const ipAddress = {
              ipAddress: data.ip || '168.212.226.204',
            };
            this.fetchingIpAddress = false;
            (window as any).dataLayer.push(ipAddress);
          });
        void subsc.then(() => {}).catch(() => {});
      }
    } catch (error) {
      // Accessing window will throw error when using in Server Side
    }
  }
}
