import { PaymentConfigurationModel } from './payment-configuration.model';
import { PaymentOrderModel } from './payment-order.model';
import { PaymentModel } from './payment.model';
import { PaymentMethod, PaymentProvider } from './payment.provider.model';
import { SessionModel } from './session.model';
import { ApiError } from '@app/shared/models/api/api-error.model';

export type CheckoutStep =
  | 'ADDRESS'
  | 'REVIEW'
  | 'SUCCESS'
  | 'PROCESSING'
  | 'FAILURE'
  | 'OTP-VERIFICATION';

export interface AkitaCheckoutState {
  session: SessionModel | null;
  order: PaymentOrderModel | null;
  orderId: string | null;
  payment: PaymentModel | null;
  paymentId: string | null;

  userOrders: Array<PaymentOrderModel> | null;

  configurations: Array<PaymentConfigurationModel> | null;

  paymentProvider: PaymentProvider | null;
  paymentMethod: PaymentMethod | null;

  step: CheckoutStep;

  ckoPaymentToken: string | null;
  ckoCardHolderName: string | null;

  isExpressCheckoutOrder: boolean;

  riskJsDeviceSessionId: string | null;
  isRiskJsInitialized: boolean;
  isRiskJsDataPublished: boolean;

  showTabbyPromo: boolean | null;
  showTamaraPromo: boolean | null;
  showQuaraPromo: boolean | null;
  showBaseetaPromo: boolean | null;

  showApplePay: boolean | null;
  showGooglePay: boolean | null;

  isRetryPaymentPopupOpen: boolean;
  isSubmitting: boolean;
  isPlacingOrder: boolean;

  gotDiscountAfterLocked: boolean;

  fetchingConfigurations: boolean;
  erroFetchingConfigurations: ApiError | null;

  updatingSession: boolean;
  errorUpdatingSession: ApiError | null;

  updatingOrder: boolean;
  errorUpdatingOrder: ApiError | null;

  fetchingOrder: boolean;
  errorFetchingOrder: ApiError | null;

  updatingPayment: boolean;
  errorUpdatingPayment: ApiError | null;

  fetchingPayment: boolean;
  errorFetchingPayment: ApiError | null;

  fetchingSession: boolean;
  errorFetchingSession: ApiError | null;

  errorStcPayment: ApiError | null;
}

export function createInitialCheckoutState(): AkitaCheckoutState {
  return {
    session: null,
    order: null,
    orderId: null,
    userOrders: null,
    payment: null,
    paymentId: null,
    configurations: null,
    paymentProvider: null,
    paymentMethod: null,
    step: 'ADDRESS',

    ckoPaymentToken: null,
    riskJsDeviceSessionId: null,
    ckoCardHolderName: null,

    isExpressCheckoutOrder: false,

    showTabbyPromo: null,
    showTamaraPromo: null,
    showQuaraPromo: null,
    showApplePay: null,
    showGooglePay: null,
    showBaseetaPromo: null,

    isRetryPaymentPopupOpen: false,
    isSubmitting: false,
    isPlacingOrder: false,

    gotDiscountAfterLocked: false,

    fetchingConfigurations: false,
    erroFetchingConfigurations: null,

    updatingSession: false,
    errorUpdatingSession: null,

    fetchingSession: false,
    errorFetchingSession: null,

    updatingOrder: false,
    errorUpdatingOrder: null,

    fetchingOrder: false,
    errorFetchingOrder: null,

    updatingPayment: false,
    errorUpdatingPayment: null,

    fetchingPayment: false,
    errorFetchingPayment: null,

    isRiskJsInitialized: false,
    isRiskJsDataPublished: false,

    errorStcPayment: null,
  };
}
