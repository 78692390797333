import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import {
  AkitaRemoteConfigStore,
  AkitaRemoteConfigState,
} from '@app/akita/api/remote-config/state/remote-config.store';
import { Observable, of, distinctUntilChanged, map } from 'rxjs';
import {
  DeliveryEstimate,
  EstimateDays,
  parseDeliveryEstimateFromJsonArray,
} from '../models/delivery-estimate.model';
import { SharedImageCategory } from '../models/shared-category-images.model';
import { NewsletterSubtitle } from '../models/newsletter-subtitle.model';
import { AkitaProductsQuery } from '../../products/state/products.query';
import { CarouselBannerSlide } from '../models/carousel-banner-slide.model';
import { SUPPORTED_BUYBACK_COUNTRIES } from '@app/buyback/views/buyback-home/buyback-home.view';
import { PopularDevice, PopularDevices } from '../models/popular-devices.model';

const DEFAULT_DELIVERY_MIN_DAYS = 5;
const DEFAULT_DELIVERY_MAX_DAYS = 7;

const TEST_PRODUCT_PAGE_VALUES = ['original', 'ppVar1'];

@Injectable({ providedIn: 'root' })
export class AkitaRemoteConfigQuery extends Query<AkitaRemoteConfigState> {
  constructor(
    protected store: AkitaRemoteConfigStore,
    private readonly akitaProductsQuery: AkitaProductsQuery
  ) {
    super(store);
  }

  public get sharedImageCategory(): SharedImageCategory | null {
    console.log(
      'this.getValue().sharedImageCategory',
      this.getValue().sharedImageCategory
    );
    return this.getValue().sharedImageCategory;
  }

  public selectSharedImageCategory(): Observable<SharedImageCategory | null> {
    return this.select().pipe(
      map((state: AkitaRemoteConfigState) => state.sharedImageCategory),
      distinctUntilChanged()
    );
  }

  public get newsletterSubtitle(): NewsletterSubtitle | null {
    return this.getValue().newsletterSubtitle;
  }

  public getNewsletterSubtitle(): string | null {
    const currentLang = this.akitaProductsQuery.language || 'en';
    let subtitle = null;
    const stateValue = this.getValue().newsletterSubtitle;
    if (stateValue && stateValue[currentLang]) {
      subtitle = stateValue[currentLang] || null;
    }

    return subtitle;
  }

  public selectAnnouncementBarText(): Observable<NewsletterSubtitle | null> {
    return this.select().pipe(
      map((state: AkitaRemoteConfigState) => state.announcementBarText),
      distinctUntilChanged()
    );
  }

  public get sendWebReviewsTo(): string | null {
    return this.getValue().sendWebReviewsTo || null;
  }

  public get enableBuybackFeature(): boolean {
    return Boolean(this.getValue().enableBuybackFeature);
  }

  public getDeliveryEstimateForCountry(country?: string | null): EstimateDays {
    const list = parseDeliveryEstimateFromJsonArray(this.getValue().deliveryEstimates);
    let estimate: EstimateDays = new EstimateDays(
      DEFAULT_DELIVERY_MIN_DAYS,
      DEFAULT_DELIVERY_MAX_DAYS
    );
    if (country) {
      const parsedCountry = `${country || ''}`.toUpperCase();
      for (const item of list) {
        if (item.country === parsedCountry) {
          estimate = item.values;
          break;
        }
      }
    }
    return estimate;
  }

  public selectEnableBuybackFeature(): Observable<boolean> {
    return this.select().pipe(
      map(
        (state: AkitaRemoteConfigState) =>
          state.enableBuybackFeature &&
          SUPPORTED_BUYBACK_COUNTRIES.includes(this.akitaProductsQuery.country)
      ),
      distinctUntilChanged()
    );
  }

  public selectPopularDevices(): Observable<PopularDevices | null> {
    return this.select().pipe(
      map((state: AkitaRemoteConfigState) => state.popularDevices),
      distinctUntilChanged()
    );
  }

  public selectPopularDevice(
    category: string | null,
    brand: string | null
  ): Observable<PopularDevice | null> {
    return this.select().pipe(
      map((state: AkitaRemoteConfigState) => {
        const popularDevices = state.popularDevices;
        if (
          popularDevices &&
          category &&
          brand &&
          popularDevices[category] &&
          popularDevices[category][brand]
        ) {
          return popularDevices[category][brand];
        }
        return null;
      }),
      distinctUntilChanged()
    );
  }

  public selectCarouselBannerSlides(): Observable<Array<CarouselBannerSlide> | null> {
    return this.select().pipe(
      map((state: AkitaRemoteConfigState) => state.carouselBannerSlides),
      distinctUntilChanged()
    );
  }

  public selectTestProductPage(): Observable<string> {
    return this.select().pipe(
      map((state: AkitaRemoteConfigState) =>
        TEST_PRODUCT_PAGE_VALUES.includes(state.testProductPage)
          ? state.testProductPage
          : 'original'
      ),
      distinctUntilChanged()
    );
  }

  public selectAddressSearch(): Observable<boolean> {
    return this.select().pipe(
      map((state: AkitaRemoteConfigState) => state.addressSearch),
      distinctUntilChanged()
    );
  }

  public selectExpressCheckout(): Observable<boolean | null> {
    return this.select().pipe(
      map((state: AkitaRemoteConfigState) => state.expressCheckout),
      distinctUntilChanged()
    );
  }

  public selectIsLoading(): Observable<boolean> {
    return this.select().pipe(
      map((state: AkitaRemoteConfigState) => state.isLoading),
      distinctUntilChanged()
    );
  }

  public selectDeliveryEstimateForCountry(
    country?: string | null
  ): Observable<EstimateDays> {
    const defaultEstimate = new EstimateDays(
      DEFAULT_DELIVERY_MIN_DAYS,
      DEFAULT_DELIVERY_MAX_DAYS
    );
    return (
      this.select().pipe(
        map((state: AkitaRemoteConfigState) => {
          const list = state.deliveryEstimates;
          if (country) {
            const parsedCountry = `${country || ''}`.toUpperCase();
            let estimate = list.find((x) => x.country === parsedCountry);
            if (estimate) {
              return estimate.values;
            } else {
              estimate = list.find((x) => x.country === 'SA');
              return estimate?.values || defaultEstimate;
            }
          }
          return defaultEstimate;
        }),
        distinctUntilChanged()
      ) || of(defaultEstimate)
    );
  }

  public selectDeliveryEstimate(): Observable<Array<DeliveryEstimate | null>> {
    return this.select().pipe(
      map((state: AkitaRemoteConfigState) => state.deliveryEstimates || null),
      distinctUntilChanged()
    );
  }

  public selectSortedPaymentMethods(): Observable<Array<string> | null> {
    return this.select().pipe(
      map((state: AkitaRemoteConfigState) => state.sortedPaymentMethods),
      distinctUntilChanged()
    );
  }
}
