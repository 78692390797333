export class BuybackBankDetails {
  public name: string;
  public iban: string;
  public swift: string;

  constructor() {
    this.name = '';
    this.iban = '';
    this.swift = '';
  }

  public static fromJson(data?: Record<string, any> | null): BuybackBankDetails | null {
    const bankDetails = new BuybackBankDetails();
    if (!data?.length) {
      return null;
    }
    bankDetails.name = data?.name || '';
    bankDetails.iban = data?.iban || data?.[0]?.iban || '';
    bankDetails.swift = data?.swift || '';

    return bankDetails;
  }
}
