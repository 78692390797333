import { Injectable, NgZone } from '@angular/core';
import { applyTransaction, logAction } from '@datorama/akita';
import {
  AkitaRemoteConfigStore,
  DEFAULT_DELIVERY_ESTIMATES,
} from '@app/akita/api/remote-config/state/remote-config.store';
import { RemoteConfigModel } from '../models/remote-config.model';
import { Observable, Observer, Subscription, distinctUntilChanged } from 'rxjs';
import { FirebaseService } from '@app/akita/firebase/services/firebase.service';
import {
  GoogleOptimizeExperiments,
  GoogleOptimizeService,
} from '../services/google-optimize.service';

import {
  remoteConfigActivate,
  remoteConfigFetchAndActivate,
  remoteConfigGetBoolean,
  remoteConfigGetString,
} from '@app/shared/firebase/firebase.wrapper';
import { getValue } from '@firebase/remote-config';
import { AkitaProductsQuery } from '../../products/state/products.query';
import { parseNewsletterSubtitleFromJsonString } from '../models/newsletter-subtitle.model';
import { parseSharedImageCategoryFromJsonString } from '../models/shared-category-images.model';
import { parsePopularDevicesFromJson } from '../models/popular-devices.model';
import { AkitaRouterQuery } from '@app/akita/router/state/router.query';
import { parseCarouselBannerImages } from '../models/carousel-banner-slide.model';
import { MSClarityService } from '@app/shared/services/msclarity.service';
import { GoogleAnalyticsService } from '@app/core/services/google-analytics.service';

const DEFAULT_REMOTE_CONFIG: Partial<RemoteConfigModel> = {
  featureAppUpdate: false,
  // cardOnlyDiscountCodes: new Array(0),
  deliveryEstimates: DEFAULT_DELIVERY_ESTIMATES,
};

@Injectable({ providedIn: 'root' })
export class AkitaRemoteConfigService {
  constructor(
    private readonly zone: NgZone,
    private readonly store: AkitaRemoteConfigStore,
    private readonly firebaseService: FirebaseService,
    private readonly googleOptimizeService: GoogleOptimizeService,
    private readonly akitaProductsQuery: AkitaProductsQuery,
    private readonly akitaRouterQuery: AkitaRouterQuery,
    private readonly msClarityService: MSClarityService,
    private readonly analyticsService: GoogleAnalyticsService
  ) {
    this.getRemoteConfigAsync();
  }

  public monitorExperiments(): Subscription {
    const out = new Subscription();
    this.zone.runOutsideAngular(() => {
      out.add(
        this.googleOptimizeService
          .monitorExperiments()
          .pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)))
          .subscribe({
            next: (experiments: GoogleOptimizeExperiments) => {
              if (Object.keys(experiments).length !== 0) {
                this.zone.run(() => {
                  console.log('experiments', experiments);
                  applyTransaction(() => {
                    logAction('monitorExperiments()');

                    if (experiments.AddressSearch !== undefined) {
                      this.store.updateAddressSearch(experiments.AddressSearch);
                    }

                    if (experiments.FeatureCashOnDelivery) {
                      // this.store.updateFeatureCashOnDelivery(
                      //   experiments.FeatureCashOnDelivery
                      // );
                    }
                  });
                });
              }
            },
          })
      );
    });
    return out;
  }
  /*
  private loadCardDiscountsFromWindow(): void {
    this.zone.runOutsideAngular(() => {
      let discounts: Array<CardOnlyDiscountCodes> = new Array(0);
      try {
        const windowCardOnlyDiscount = (window as any).POPSY_CARD_ONLY_DISCOUNT;
        discounts = parseCardOnlyDiscountCodesFromJsonArray(
          windowCardOnlyDiscount && windowCardOnlyDiscount.length > 0
            ? windowCardOnlyDiscount
            : POPSY_CARD_ONLY_DISCOUNT
        );
      } catch (error) {}
    });
  } */

  public refreshPaymentProvider(): void {
    const country = this.akitaProductsQuery.validateLocale().country;
    // CHECKOUT.com for KW and QA
    if (country === 'KW' || country === 'QA') {
      // this.store.updatePaymentProvider('CHECKOUT');
    }
    // EXPERIMENT ONLY FOR AE
    if (country !== 'AE') {
      // this.store.updatePaymentProvider('STRIPE_V2');
    }
  }

  public getRemoteConfigAsync(): Subscription {
    return this.getRemoteConfig().subscribe({
      next: () => {},
      error: () => {},
    });
  }

  public getRemoteConfig(): Observable<Partial<RemoteConfigModel> | null> {
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('getRemoteConfig()');
        this.store.setLoading(true);
        this.store.setError(null);
      });
    });

    return new Observable((observer: Observer<Partial<RemoteConfigModel>>) => {
      this.zone.runOutsideAngular(() => {
        this.firebaseService.remoteConfig
          .then((remoteConfig: any | null) => {
            if (remoteConfig) {
              // remoteConfig cache
              // 1 minute for dev and prod
              remoteConfig.settings.minimumFetchIntervalMillis = 10000;

              const stored = remoteConfig['_storageCache']['activeConfig'];
              const storedConfig = this.parseRemoteConfig(stored);
              this.zone.run(() => {
                applyTransaction(() => {
                  logAction('set RemoteConfig from Cache - done');
                  this.store.updateConfig(storedConfig);
                  this.store.setLoading(false);
                  this.store.setError(null);
                });
              });

              remoteConfigFetchAndActivate(remoteConfig)
                .then(() => {
                  const config = this.parseRemoteConfig(remoteConfig);
                  this.zone.run(() => {
                    applyTransaction(() => {
                      logAction('getRemoteConfig() - done 1');
                      this.store.updateConfig(config);
                      this.store.setLoading(false);
                      this.store.setError(null);
                    });
                  });

                  this.setABTestTagging();
                  observer.next(config);
                  observer.complete();
                })
                .catch((error: unknown) => {
                  this.zone.run(() => {
                    applyTransaction(() => {
                      logAction('getRemoteConfig() - error');
                      this.store.setLoading(false);
                      this.store.setError(error);
                    });
                  });

                  observer.next(DEFAULT_REMOTE_CONFIG);
                  observer.complete();
                });
            } else {
              observer.next(DEFAULT_REMOTE_CONFIG);
              observer.complete();
            }
          })
          .catch(() => {
            /** Remote config not available */
          });
      });
    });
  }

  public activateRemoteConfig(): Observable<Partial<RemoteConfigModel> | null> {
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('activateRemoteConfig()');
        this.store.setLoading(true);
        this.store.setError(null);
      });
    });

    return new Observable((observer: Observer<Partial<RemoteConfigModel>>) => {
      this.zone.runOutsideAngular(() => {
        this.firebaseService.remoteConfig
          .then((remoteConfig: any | null) => {
            if (remoteConfig) {
              remoteConfigActivate(remoteConfig)
                .then(() => {
                  const config = this.parseRemoteConfig(remoteConfig);
                  this.zone.run(() => {
                    applyTransaction(() => {
                      logAction('activateRemoteConfig() - done');
                      this.store.updateConfig(config);
                      this.store.setLoading(false);
                      this.store.setError(null);
                    });
                  });

                  observer.next(config);
                  observer.complete();
                })
                .catch((error: unknown) => {
                  this.zone.run(() => {
                    applyTransaction(() => {
                      logAction('activateRemoteConfig() - error');
                      this.store.setLoading(false);
                      this.store.setError(error);
                    });
                  });

                  observer.next(DEFAULT_REMOTE_CONFIG);
                  observer.complete();
                });
            } else {
              this.zone.run(() => {
                applyTransaction(() => {
                  logAction('activateRemoteConfig() - error');
                  this.store.setLoading(false);
                  this.store.setError({
                    code: 'RC_NOT_AVAILABLE',
                    message: 'Remote Config not available',
                  });
                });
              });

              observer.next(DEFAULT_REMOTE_CONFIG);
              observer.complete();
            }
          })
          .catch(() => {
            /** Remote config not available */
          });
      });
    });
  }

  public activateRemoteConfigAsync(): Subscription {
    return this.activateRemoteConfig().subscribe({
      next: () => {},
      error: () => {},
    });
  }

  private setABTestTagging(): void {
    // express checkout ab testing
    const expressCheckout = this.store.getValue().expressCheckout;
    this.msClarityService.setTag(
      'abtest_express_checkout',
      expressCheckout ? 'enabled' : 'disabled'
    );
    this.analyticsService.setUserProperties({
      ab_test: expressCheckout ? 'express_checkout_enabled' : 'express_checkout_disabled',
    });
  }

  private parseRemoteConfig(remoteConfig: any | null): Partial<RemoteConfigModel> {
    let config: Partial<RemoteConfigModel> = { ...DEFAULT_REMOTE_CONFIG };

    try {
      if (remoteConfig) {
        config = {
          deliveryEstimates: DEFAULT_DELIVERY_ESTIMATES,

          featureAppUpdate: remoteConfigGetBoolean(remoteConfig, 'feature_app_update'),

          popularDevices: parsePopularDevicesFromJson(
            remoteConfigGetString(remoteConfig, 'popular_devices')
          ),

          carouselBannerSlides: parseCarouselBannerImages(
            remoteConfigGetString(remoteConfig, 'carousel_banner_images')
          ),

          sharedImageCategory: parseSharedImageCategoryFromJsonString(
            remoteConfigGetString(remoteConfig, 'shared_image_category')
          ),
          newsletterSubtitle: parseNewsletterSubtitleFromJsonString(
            remoteConfigGetString(remoteConfig, 'newsletter_subtitle')
          ),
          announcementBarText: parseNewsletterSubtitleFromJsonString(
            remoteConfigGetString(remoteConfig, 'announcement_bar_text')
          ),
          sendWebReviewsTo: remoteConfigGetString(remoteConfig, 'send_web_reviews_to'),

          enableBuybackFeature: remoteConfigGetBoolean(
            remoteConfig,
            'enable_buyback_feature'
          ),
          expressCheckout: remoteConfigGetBoolean(remoteConfig, 'express_checkout'),

          sortedPaymentMethods: JSON.parse(
            getValue(remoteConfig, 'sorted_payment_methods').asString()
          ) as Array<string>,

          testProductPage:
            remoteConfigGetString(remoteConfig, 'test_product_page') ||
            this.akitaRouterQuery.queryParams['t'] ||
            'original',
        };
        console.log('config', config);
      }
    } catch (error) {}

    return config;
  }
}
