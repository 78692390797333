export const PRODUCT_SORTING_BEST_SALES = 'BEST_SALES';
export const PRODUCT_SORTING_CHEAPEST_FIRST = 'CHEAPEST_FIRST';
export const PRODUCT_SORTING_CHEAPEST_LAST = 'CHEAPEST_LAST';
export const PRODUCT_SORTING_RANKED_BY_SALES = 'RANKED_BY_SALES';

export const ALL_PRODUCT_SORT_OPTIONS = [
  PRODUCT_SORTING_BEST_SALES,
  PRODUCT_SORTING_CHEAPEST_FIRST,
  PRODUCT_SORTING_CHEAPEST_LAST,
  PRODUCT_SORTING_RANKED_BY_SALES,
];

export type ProductSearchSortMode =
  | 'BEST_SALES'
  | 'CHEAPEST_FIRST'
  | 'CHEAPEST_LAST'
  | 'RANKED_BY_SALES'
  | null;

export const PRODUCT_SEARCH_FILTERS_MULTIPLE_ALLOWED = [
  'brand',
  'model',
  'storage',
  'color',
  'cpu',
  'graphics',
  'ram',
  'launch_date',
  'screen_size',
  'storage_type',
  'category',
  'series',
];

export const normalizeFilterValue = (name?: string | null | unknown): string => {
  let out = `${name || ''}`;

  if (out === 'RED') {
    out = '(PRODUCT)RED';
  } else if (out === 'JET_BLACK') {
    out = 'JET BLACK';
  }
  return out;
};

export class ProductSearchFilters {
  public page?: number | null;
  public limit?: number | null;
  public currency?: string | null;
  public price_min?: number | null;
  public price_max?: number | null;
  public sorting?: ProductSearchSortMode;
  public country?: string | null;
  public category?: Array<string> | null;
  public brand?: Array<string> | null;
  public model?: Array<string> | null;
  public series?: Array<string> | null;
  public storage?: Array<string> | null;
  public color?: Array<string> | null;
  public language?: string | null;
  public text?: string | null;
  public is_featured?: boolean | null;
  public cpu?: Array<string> | null;
  public graphics?: Array<string> | null;
  public launch_date?: Array<number> | null;
  public ram?: Array<string> | null;
  public screen_size?: Array<string> | null;
  public storage_type?: Array<string> | null;
  public keywords?: string | null;

  constructor() {
    this.page = null;
    this.limit = null;
    this.currency = null;
    this.price_min = null;
    this.price_max = null;
    this.sorting = null;
    this.country = null;
    this.category = null;
    this.brand = null;
    this.model = null;
    this.series = null;
    this.storage = null;
    this.color = null;
    this.language = null;
    this.text = null;
    this.is_featured = null;
    this.cpu = null;
    this.graphics = null;
    this.launch_date = null;
    this.ram = null;
    this.screen_size = null;
    this.storage_type = null;
  }
}

export const parseProductSearchFilters = (
  data?: Record<string, any> | null
): ProductSearchFilters => {
  const filters = new ProductSearchFilters();
  if (data) {
    filters.page = data.page || null;
    filters.limit = data.limit || null;
    filters.currency = data.currency || null;
    filters.price_min = data.price_min || data.priceMin || null;
    filters.price_max = data.price_max || data.priceMax || null;
    filters.sorting = data.sorting || null;
    filters.country = data.country || null;
    filters.category = data.category || null;
    filters.brand = data.brand || null;
    filters.model = data.model || null;
    filters.series = data.series || null;
    filters.storage = data.storage || null;
    filters.color = data.color || null;
    filters.language = data.language || null;
    filters.text = data.text || null;
    filters.is_featured = data.is_featured || null;
    filters.cpu = data.cpu || null;
    filters.graphics = data.graphics || null;
    filters.launch_date = data.launch_date || null;
    filters.ram = data.ram || null;
    filters.screen_size = data.screen_size || null;
    filters.storage_type = data.storage_type || null;
    filters.keywords = data.keywords || null;
  }
  return filters;
};

export const generateFiltersID = (filters?: ProductSearchFilters | null): string => {
  let path = '';
  if (filters) {
    for (const key of Object.keys(filters || {})) {
      if (key !== 'page') {
        const value = (filters as any)[key];
        if ((!Array.isArray(value) && value) || (Array.isArray(value) && value.length)) {
          if (PRODUCT_SEARCH_FILTERS_MULTIPLE_ALLOWED.includes(`${key || '-'}`)) {
            path = `${path}&${key}=${(value as Array<string>).join('|')}`;
          } else {
            path = `${path}&${key}=${value}`;
          }
        }
      }
    }
  }

  return path;
};
