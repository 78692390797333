import { Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { LanguageGuard } from './guards/language.guard';

// Custom matcher to check for subdomains
export function virginSubdomainMatcher(/* url: UrlSegment[] */) {
  const host = window.location.hostname;
  const subdomain = host.split('.')[0]; // Get the first part of the hostname

  // Change 'sub' to the desired subdomain
  if (subdomain === 'virgin-demo') {
    return { consumed: [] };
  }

  return null;
}

export const VIRGIN_ROUTES: Routes = [
  // Collection Page
  {
    path: 'c',
    data: {
      view: 'VIRGIN_COLLECTION',
      step: 'COLLECTION_ROOT',
      navigation: true,
      analyticsName: 'collection',
      fragment: ['c'],
    },
    resolve: {},
    children: [
      {
        path: ':section',
        data: {
          view: 'VIRGIN_HOME',
          step: 'COLLECTION',
          navigation: true,
          analyticsName: 'collection',
          fragment: ['c', ':section'],
        },
        children: [
          {
            path: ':key',
            loadComponent: () =>
              import('../virgin/products/collection-page/view/collection-page.view').then(
                (c) => c.CollectionPageViewComponent
              ),
            canActivate: [LanguageGuard, AuthGuard],
            data: {
              view: 'VIRGIN_HOME',
              step: 'PRODUCT_SEARCH',
              navigation: true,
              analyticsName: 'products',
              fragment: ['c', ':section', ':key'],
            },
            resolve: {},
          },
        ],
      },
    ],
  },

  // Products Details Page
  {
    path: 'p',
    canActivate: [LanguageGuard, AuthGuard],
    data: {
      view: 'VIRGIN_PRODUCTS',
      step: 'PRODUCT_ROOT',
      navigation: true,
      analyticsName: 'product',
      fragment: ['p'],
    },
    resolve: {},
    children: [
      {
        path: ':tid',
        pathMatch: 'full',
        loadComponent: () =>
          import(
            /* webpackChunkName: "products-details" */ '../virgin/products/details/view/product-details.view'
          ).then((module) => module.ProductDetailsViewComponent),
        canActivate: [LanguageGuard, AuthGuard],
        data: {
          view: 'VIRGIN_PRODUCTS',
          step: 'PRODUCT_DETAILS',
          navigation: true,
          analyticsName: 'product',
          fragment: ['p', ':tid'],
        },
        resolve: {},
      },
    ],
  },

  // Cart Page
  {
    path: 'cart',
    loadComponent: () =>
      import(
        /* webpackChunkName: "cart" */ '../virgin/cart/view/cart.view.component'
      ).then((module) => module.CartViewComponent),
    data: {
      view: 'VIRGIN_CART',
      step: 'CART',
      fragment: ['cart'],
      analyticsName: 'cart',
    },
    canActivate: [LanguageGuard],
  },

  // Checkout Session Page
  {
    path: 'checkout',
    canActivate: [LanguageGuard, AuthGuard],
    data: {
      view: 'CHECKOUT',
      step: 'CHECKOUT_ROOT',
      navigation: true,
      analyticsName: 'checkout',
      fragment: ['checkout'],
    },
    resolve: {},
    children: [
      {
        path: ':pid',
        data: {
          view: 'SESSION',
          step: 'VIRGIN_SESSION',
          navigation: true,
          analyticsName: 'CheckoutSession',
          fragment: ['checkout', ':pid'],
        },
        // resolve: {},

        children: [
          // Create Delivery Overview Page
          {
            path: 'overview',
            loadComponent: () =>
              import(
                /* webpackChunkName: "checkout-session" */ '../virgin/checkout/views/checkout-session/checkout-session.view'
              ).then((module) => module.CheckoutSessionComponent),
            data: {
              view: 'SESSION',
              step: 'VIRGIN_SESSION',
              navigation: true,
              analyticsName: 'CheckoutSession',
              fragment: ['delivery', ':pid', 'overview'],
            },
          },

          // Create checkout Payment Checkout.com Success
          {
            path: 'cko-success',
            loadComponent: () =>
              import(
                /* webpackChunkName: "cko-callback" */ '../checkout/views/cko-callback/cko-callback.view'
              ).then((module) => module.CheckoutDotComCallbackComponent),
            data: {
              view: 'SESSION',
              step: 'CKO_SUCCESS',
              analyticsName: 'checkout_create_cko_success',
              fragment: ['checkout', ':pid', 'cko-success'],
            },
          },
          // Create checkout Payment Checkout.com error
          {
            path: 'cko-failure',
            loadComponent: () =>
              import(
                /* webpackChunkName: "cko-callback" */ '../checkout/views/cko-callback/cko-callback.view'
              ).then((module) => module.CheckoutDotComCallbackComponent),
            data: {
              view: 'SESSION',
              step: 'CKO_FAIL',
              analyticsName: 'checkout_create_cko_fail',
              fragment: ['checkout', ':pid', 'cko-failure'],
            },
          },
          // Create checkout Payment Tabby Success
          {
            path: 'tabby-success',
            loadComponent: () =>
              import(
                /* webpackChunkName: "tabby-callback" */ '../checkout/views/tabby-callback/tabby-callback.view'
              ).then((module) => module.TabbyCallbackComponent),
            data: {
              view: 'SESSION',
              step: 'TABBY_SUCCESS',
              analyticsName: 'checkout_create_tabby_success',
              fragment: ['checkout', ':pid', 'tabby-success'],
            },
          },
          // Create checkout Payment Tabby Cancel
          {
            path: 'tabby-cancel',
            loadComponent: () =>
              import(
                /* webpackChunkName: "tabby-callback" */ '../checkout/views/tabby-callback/tabby-callback.view'
              ).then((module) => module.TabbyCallbackComponent),
            data: {
              view: 'SESSION',
              step: 'TABBY_CANCEL',
              analyticsName: 'checkout_create_tabby_cancel',
              fragment: ['checkout', ':pid', 'tabby-cancel'],
            },
          },
          // Create checkout Payment Tabby Failure
          {
            path: 'tabby-failure',
            loadComponent: () =>
              import(
                /* webpackChunkName: "tabby-callback" */ '../checkout/views/tabby-callback/tabby-callback.view'
              ).then((module) => module.TabbyCallbackComponent),
            data: {
              view: 'SESSION',
              step: 'TABBY_FAILURE',
              analyticsName: 'checkout_create_tabby_failure',
              fragment: ['checkout', ':pid', 'tabby-failure'],
            },
          },

          // Create checkout Payment Quara Success
          {
            path: 'quara-success',
            loadComponent: () =>
              import(
                /* webpackChunkName: "quara-callback" */ '../checkout/views/quara-callback/quara-callback.view'
              ).then((module) => module.QuaraCallbackComponent),
            data: {
              view: 'SESSION',
              step: 'QUARA_SUCCESS',
              analyticsName: 'checkout_create_quara_success',
              fragment: ['checkout', ':pid', 'quara-success'],
            },
          },
          // Create checkout Payment Quara Cancel
          {
            path: 'quara-cancel',
            loadComponent: () =>
              import(
                /* webpackChunkName: "quara-callback" */ '../checkout/views/quara-callback/quara-callback.view'
              ).then((module) => module.QuaraCallbackComponent),
            data: {
              view: 'SESSION',
              step: 'QUARA_CANCEL',
              analyticsName: 'checkout_create_quara_cancel',
              fragment: ['checkout', ':pid', 'quara-cancel'],
            },
          },
          // Create checkout Payment Quara Failure
          {
            path: 'quara-failure',
            loadComponent: () =>
              import(
                /* webpackChunkName: "quara-callback" */ '../checkout/views/quara-callback/quara-callback.view'
              ).then((module) => module.QuaraCallbackComponent),
            data: {
              view: 'SESSION',
              step: 'QUARA_FAILURE',
              analyticsName: 'checkout_create_quara_failure',
              fragment: ['checkout', ':pid', 'quara-failure'],
            },
          },

          // Create checkout Payment Quara Success
          {
            path: 'baseeta-success',
            loadComponent: () =>
              import(
                /* webpackChunkName: "baseeta-callback" */ '../checkout/views/baseeta-callback/baseeta-callback.view'
              ).then((module) => module.BaseetaCallbackComponent),
            data: {
              view: 'SESSION',
              step: 'BASEETA_SUCCESS',
              analyticsName: 'checkout_create_baseeta_success',
              fragment: ['checkout', ':pid', 'baseeta-success'],
            },
          },
          // Create checkout Payment Quara Failure
          {
            path: 'baseeta-failure',
            loadComponent: () =>
              import(
                /* webpackChunkName: "baseeta-callback" */ '../checkout/views/baseeta-callback/baseeta-callback.view'
              ).then((module) => module.BaseetaCallbackComponent),
            data: {
              view: 'SESSION',
              step: 'BASEETA_FAILURE',
              analyticsName: 'checkout_create_baseeta_failure',
              fragment: ['checkout', ':pid', 'baseeta-failure'],
            },
          },

          // Create checkout Payment Quara Success
          {
            path: 'stc-success',
            loadComponent: () =>
              import(
                /* webpackChunkName: "stc-callback" */ '../checkout/views/stc-callback/stc-callback.view'
              ).then((module) => module.StcPayCallbackComponent),
            data: {
              view: 'SESSION',
              step: 'STC_PAY_SUCCESS',
              analyticsName: 'checkout_create_stc_pay_success',
              fragment: ['checkout', ':pid', 'stc-success'],
            },
          },
          // Create checkout Payment Quara Failure
          {
            path: 'stc-failure',
            loadComponent: () =>
              import(
                /* webpackChunkName: "stc-callback" */ '../checkout/views/stc-callback/stc-callback.view'
              ).then((module) => module.StcPayCallbackComponent),
            data: {
              view: 'SESSION',
              step: 'STC_PAY_FAILURE',
              analyticsName: 'checkout_create_stc_pay_failure',
              fragment: ['checkout', ':pid', 'stc-failure'],
            },
          },

          // Create checkout Payment Paypal Success
          {
            path: 'paypal-success',
            loadComponent: () =>
              import(
                /* webpackChunkName: "paypal-callback" */ '../checkout/views/paypal-callback/paypal-callback.view'
              ).then((module) => module.PaypalCallbackComponent),
            data: {
              view: 'SESSION',
              step: 'PAYPAL_SUCCESS',
              analyticsName: 'checkout_create_paypal_success',
              fragment: ['checkout', ':pid', 'paypal-success'],
            },
          },
          // Create checkout Payment PayPal Cancel
          {
            path: 'paypal-cancel',
            loadComponent: () =>
              import(
                /* webpackChunkName: "paypal-callback" */ '../checkout/views/paypal-callback/paypal-callback.view'
              ).then((module) => module.PaypalCallbackComponent),
            data: {
              view: 'SESSION',
              step: 'PAYPAL_CANCEL',
              analyticsName: 'checkout_create_paypal_cancel',
              fragment: ['checkout', ':pid', 'paypal-cancel'],
            },
          },
          // Create checkout Payment PayPal Failure
          {
            path: 'paypal-failure',
            loadComponent: () =>
              import(
                /* webpackChunkName: "paypal-callback" */ '../checkout/views/paypal-callback/paypal-callback.view'
              ).then((module) => module.PaypalCallbackComponent),
            data: {
              view: 'SESSION',
              step: 'PAYPAL_FAILURE',
              analyticsName: 'checkout_create_paypal_failure',
              fragment: ['checkout', ':pid', 'paypal-failure'],
            },
          },

          // Createcheckout Payment Tamara Success
          {
            path: 'tamara-success',
            loadComponent: () =>
              import(
                /* webpackChunkName: "tamara-callback" */ '../checkout/views/tamara-callback/tamara-callback.view'
              ).then((module) => module.TamaraCallbackComponent),
            data: {
              view: 'SESSION',
              step: 'TAMARA_SUCCESS',
              analyticsName: 'checkout_create_tamara_success',
              fragment: ['checkout', ':pid', 'tamara-success'],
            },
          },
          // Create checkout Payment Tamara Cancel
          {
            path: 'tamara-cancel',
            loadComponent: () =>
              import(
                /* webpackChunkName: "tamara-callback" */ '../checkout/views/tamara-callback/tamara-callback.view'
              ).then((module) => module.TamaraCallbackComponent),
            data: {
              view: 'SESSION',
              step: 'TAMARA_CANCEL',
              analyticsName: 'checkout_create_tamara_cancel',
              fragment: ['checkout', ':pid', 'tamara-cancel'],
            },
          },
          // Create checkout Payment Tamara Failure
          {
            path: 'tamara-failure',
            loadComponent: () =>
              import(
                /* webpackChunkName: "tamara-callback" */ '../checkout/views/tamara-callback/tamara-callback.view'
              ).then((module) => module.TamaraCallbackComponent),
            data: {
              view: 'SESSION',
              step: 'TAMARA_FAILURE',
              analyticsName: 'checkout_create_tamara_failure',
              fragment: ['checkout', ':pid', 'tamara-failure'],
            },
          },
        ],
      },
      {
        path: '',
        loadComponent: () =>
          import(
            // eslint-disable-next-line max-len
            /* webpackChunkName: "checkout-product-redirect" */ '../checkout/views/checkout-product-redirect/checkout-product-redirect.view'
          ).then((module) => module.CheckoutProductRedirectComponent),
        data: {
          view: 'SESSION',
          step: 'CHECKOUT_PRODUCT_REDIRECT',
          navigation: true,
          analyticsName: 'CheckoutProductRedirect',
          fragment: [''],
        },
      },
    ],
  },

  // Track Order Page
  {
    path: 'order',
    data: {
      view: 'ORDER',
      step: 'ORDER',
      fragment: ['order'],
      analyticsName: 'order',
    },
    canActivate: [LanguageGuard],
    resolve: {},
    children: [
      // Track Public ORDER
      {
        path: 'search',
        pathMatch: 'full',
        loadComponent: () =>
          import(
            /* webpackChunkName: "delivery-tracker" */ '../checkout/views/order-tracker/order-tracker.view'
          ).then((module) => module.OrderTrackerComponent),
        data: {
          view: 'VIRGIN-SEARCH-ORDER',
          step: 'INFORMATION',
          fragment: ['order', 'search'],
          analyticsName: 'search-order',
        },
        resolve: {},
      },
      // Delivery Pages
      {
        path: ':id',
        data: {
          view: 'VIRGIN-ORDER',
          step: 'ORDER',
          fragment: ['order', ':id'],
          analyticsName: 'order',
        },
        children: [
          // Track Delivery Page
          {
            path: '',
            loadComponent: () =>
              import(
                /* webpackChunkName: "delivery-tracker" */ '../virgin/order-tracker/order-tracker.view'
              ).then((module) => module.OrderTrackerComponent),
            canActivate: [AuthGuard],
            data: {
              view: 'VIRGIN-ORDER-INFO',
              step: 'INFORMATION',
              analyticsName: 'delivery_details',
              fragment: ['order', ':id'],
            },
          },
        ],
      },

      // Track Delivery Page (error)
      {
        path: '',
        loadComponent: () =>
          import(
            /* webpackChunkName: "delivery-tracker" */ '../checkout/views/order-tracker/order-tracker.view'
          ).then((module) => module.OrderTrackerComponent),
        data: {
          view: 'VIRGIN-ORDER',
          step: 'INFORMATION',
          analyticsName: 'order',
          fragment: ['order'],
        },
      },
    ],
  },

  // User Pages
  {
    path: 'user',
    data: { view: 'USER', step: 'USER', fragment: ['user'], analyticsName: 'user' },
    canActivate: [LanguageGuard, AuthGuard],
    resolve: {},
    children: [
      // Sign In Page
      {
        path: 'sign-in',
        loadComponent: () =>
          import(
            /* webpackChunkName: "sign-in-view" */ '../sign-in-view/sign-in-view.view'
          ).then((module) => module.SignInViewComponent),
        data: {
          view: 'USER',
          step: 'SIGN_IN',
          analyticsName: 'sign_in',
          fragment: ['user', 'sign-in'],
        },
      },
      // Sign Up Page
      {
        path: 'sign-up',
        loadComponent: () =>
          import(
            /* webpackChunkName: "sign-up-view" */ '../sign-up-view/sign-up-view.view'
          ).then((module) => module.SignUpViewComponent),
        data: {
          view: 'USER',
          step: 'SIGN_UP',
          analyticsName: 'sign_up',
          fragment: ['user', 'sign-up'],
        },
      },
      // Reset Password Page
      {
        path: 'reset-password',
        loadComponent: () =>
          import(
            /* webpackChunkName: "reset-password" */ '../reset-password-view/reset-password-view.view'
          ).then((module) => module.ResetPasswordViewComponent),
        data: {
          view: 'USER',
          step: 'RESET_PASSWORD',
          analyticsName: 'reset_password_request',
          fragment: ['user', 'reset-password'],
        },
      },

      // Other Users Profile Page
      {
        path: ':id',
        data: {
          view: 'VIRGIN-USER',
          step: 'PROFILE',
          fragment: ['user', ':id'],
          analyticsName: 'user',
        },
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        resolve: {},
        children: [
          {
            path: ':username',
            loadComponent: () =>
              import(
                /* webpackChunkName: "user-profile" */ '../user-profile/view/user-profile.view'
              ).then((mod) => mod.UserProfileComponent),
            data: {
              view: 'VIRGIN-USER',
              step: 'PROFILE',
              fragment: ['user', ':id', ':username'],
              analyticsName: 'user',
            },
          },
          {
            path: '',
            loadComponent: () =>
              import(
                /* webpackChunkName: "user-profile" */ '../user-profile/view/user-profile.view'
              ).then((mod) => mod.UserProfileComponent),
            data: {
              view: 'VIRGIN-USER',
              step: 'PROFILE',
              fragment: ['user', ':id'],
              analyticsName: 'user',
            },
          },
        ],
      },
      // My User Profile Page
      {
        path: '',
        loadComponent: () =>
          import(
            /* webpackChunkName: "user-profile" */ '../user-profile/view/user-profile.view'
          ).then((mod) => mod.UserProfileComponent),
        data: {
          view: 'VIRGIN-USER',
          step: 'PROFILE',
          fragment: ['user'],
          analyticsName: 'user',
          protected: true,
        },
      },
    ],
  },

  {
    path: '',
    /* pathMatch: 'full', */
    loadComponent: () =>
      import(
        /* webpackChunkName: "virgin-homne" */ '../virgin/home/view/home.view.component'
      ).then((module) => module.HomeViewComponent),
    data: {
      view: 'VIRGIN_HOME',
      step: 'ROOT',
      fragment: [''],
      analyticsName: '',
    },
    canActivate: [LanguageGuard],
  },
];
