/* eslint-disable @typescript-eslint/no-magic-numbers */
import { ChangeDetectionStrategy, Component, Input, Signal } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { CommonModule } from '@angular/common';
import { toSignal } from '@angular/core/rxjs-interop';

import { TamaraArIconComponent } from '../../../../../shared/components/icons/generated/tamara-ar/tamara-ar.component';
import { TamaraIconComponent } from '../../../../../shared/components/icons/generated/tamara/tamara.component';

@Component({
  selector: 'popsy-tamara-badge',
  templateUrl: './tamara-badge.template.html',
  styleUrls: ['./tamara-badge.style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, TamaraArIconComponent, TamaraIconComponent],
})
export class TamaraBadgeComponent {
  @Input() public size?: 'small' | 'medium' | 'default' = 'default';

  public readonly language: Signal<string>;

  constructor(private readonly translateService: TranslocoService) {
    this.language = toSignal(this.translateService.langChanges$, {
      initialValue: this.translateService.getActiveLang(),
    });
  }
}
